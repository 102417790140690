import React from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBDataTableV5, MDBIcon, MDBRow, MDBView, MDBDatePicker} from "mdbreact";
import PieChart from '../components/pieChart';
import Amplify, { Auth } from 'aws-amplify';
import FilterDateForm from '../forms/FilterDateForm';
import decode from 'jwt-decode';
import qbotHelper from "../../helpers/qbotHelper";
let loaded = false;

const TradeHistory = () => {

    const [fromDate, setFromDate] = React.useState(formatDate(new Date()));
    const [toDate, setToDate] = React.useState(formatDate(new Date()));

    const [groupBySymbolData, setGroupBySymbolData] = React.useState([]);
    const [groupByMagicData, setGroupByMagicData] = React.useState([]);
    if (false === loaded) {
        setFromDate(formatDate(new Date()))
        setToDate(formatDate(new Date()))
        loadData('symbol', fromDate, toDate);
        loadData('magic', fromDate, toDate);
        loaded = true;
    }

    function submitDatePicker(fromDate, toDate)
    {
        loadData('symbol', formatDate(toDate), formatDate(fromDate));
        loadData('magic', formatDate(toDate), formatDate(fromDate));
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return year.toString()+'-'+month.toString()+'-'+day.toString();
    }

    function getPercentages(data, type) {
        let results = [];
        let subjects = [];
        let profit = 0;
        let totalProfit = 0;

        // tally up all the profit and get a list of the subjects (magic or symbols) involved
        data.map(record => {
            if (record.profit <= 0) {
                return false;
            }
            profit = record.profit;

            totalProfit = totalProfit + profit;
            if (type === 'magic') {
                subjects[record.magic+'_'+record.buy_sell]  = [];
                subjects[record.magic+'_'+record.buy_sell]['profit'] = 0;
                subjects[record.magic+'_'+record.buy_sell]['name']  = record.symbol+'_'+record.buy_sell;
            } else {
                subjects[record.symbol+'_'+record.buy_sell]  = [];
                subjects[record.symbol+'_'+record.buy_sell]['profit'] = 0;
                subjects[record.symbol+'_'+record.buy_sell]['name']  = record.symbol+'_'+record.buy_sell;
            }
        })

        data.map(record => {

            if (record.profit <= 0) {
                return false
            }
            profit = record.profit;

            if (type === 'magic') {
                subjects[record.magic+'_'+record.buy_sell]['profit'] =
                    subjects[record.magic+'_'+record.buy_sell]['profit'] + profit;
                subjects[record.magic+'_'+record.buy_sell]['percentage'] = profit / totalProfit * 100;
            } else {
                subjects[record.symbol+'_'+record.buy_sell]['profit'] = subjects[record.symbol+'_'+record.buy_sell]['profit'] + profit;
                subjects[record.symbol+'_'+record.buy_sell]['percentage'] = profit / totalProfit * 100;
            }
        })


        return subjects;
    }


    function loadData(groupBy = null, toDate = null, fromDate = null) {
        const tokens = Auth.currentSession().then(function (e) {
            let u = e.getIdToken().payload['cognito:username'];
            let jwtToken = e.getIdToken().jwtToken;
            let cognitoProfile = decode(jwtToken);

            let url = 'https://botdashapi.fxsgnl.io/trade_history?1=1';

            if (null !== fromDate) {
                url += '&from_date='+fromDate;
            }
            if (null !== toDate) {
                url += '&to_date='+toDate;
            }
            if (null !== groupBy) {
                url += '&group='+groupBy;
            }
            fetch(url, {
                headers: {
                    "x-api-key": jwtToken
                },
            }).then((response) => response.json())
                .then((findresponse) => {
                    switch(groupBy) {
                        case ('symbol') :
                            setGroupBySymbolData(findresponse);
                            break;
                        case('magic') :
                            setGroupByMagicData(findresponse);
                            break;
                    }
                })
        });
    }

    if (loaded) {

        // first sort the data into a nice format
        let groupBySymbolHeaders = [];
        let groupByMagicHeaders = [];
        let groupBySymbolProfit = [];
        let groupByMagicProfit = [];
        let bgColours = [];
        let hoverColours = [];
        let symbolPercentageData = [];
        symbolPercentageData = getPercentages(groupBySymbolData, 'symbol');
        let i = 0;
        groupBySymbolData.map(cat => {
            if (cat.profit <= 0) {
                return false;
            }
            let type = cat.buy_sell;
            let pecentage = Number(symbolPercentageData[cat.symbol+'_'+cat.buy_sell]['percentage']).toFixed(2);
            groupBySymbolHeaders[i] = cat.symbol+' '+type+' ('+pecentage+'%)';
            groupBySymbolProfit[i] = Number(cat.profit).toFixed(2);
            bgColours[i] = "#"+Math.floor(Math.random()*16777215).toString(16);
            hoverColours[i] = "#"+Math.floor(Math.random()*16777215).toString(16);
            i++;
        });

        let percentageMagicData = [];
        percentageMagicData = getPercentages(groupByMagicData, 'magic');

        i = 0;
        groupByMagicData.map(bot => {
            if (bot.magic === 0) {
                return false;
            }

            if (bot.profit <= 0) {
               return false;
            }

            let botName = qbotHelper.getQBotName(bot.magic);
            let type = bot.buy_sell;
            let pecentage = Number(percentageMagicData[bot.magic+'_'+bot.buy_sell]['percentage']).toFixed(2);
            groupByMagicProfit[i] = Number(bot.profit).toFixed(2);
            groupByMagicHeaders[i] = botName+' '+type+' ('+pecentage+'%)';
            bgColours[i] = "#" + Math.floor(Math.random() * 16777215).toString(16);
            hoverColours[i] = "#" + Math.floor(Math.random() * 16777215).toString(16);
            i++;
        });

        return (
            <MDBContainer fluid className="p-2">
                <MDBCard narrow className='pb-3'>
                    <MDBView
                        cascade
                        className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
                    >
                        <div className='text-left'>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='th-large' className='mt-0'/>
                            </MDBBtn>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='columns' className='mt-0'/>
                            </MDBBtn>
                        </div>
                        <a href='#' className='white-text mx-3'>
                            Trade History
                        </a>
                        <div className='text-right'>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon id="refresh" icon='info-circle' className='mt-0'/>
                            </MDBBtn>
                        </div>
                    </MDBView>

                    <FilterDateForm
                        fromDate={fromDate}
                        toDate={toDate}
                        submitEvent={submitDatePicker}
                        fromDateText='Trades closed between:'
                        toDateText='And'
                    />
                    <MDBRow className='mb-5'>
                        <PieChart
                            title="Profit By Symbol"
                            chartHeaders={groupBySymbolHeaders}
                            data={groupBySymbolProfit}
                            bgColours={bgColours}
                            hoverColours={hoverColours}
                        />
                        <PieChart
                            title="Profit By Magic"
                            chartHeaders={groupByMagicHeaders}
                            data={groupByMagicProfit}
                            bgColours={bgColours}
                            hoverColours={hoverColours}
                        />
                    </MDBRow>
                </MDBCard>
            </MDBContainer>
        );
    }
};

export default TradeHistory;