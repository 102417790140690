import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBView,
  MDBDataTableV5
} from 'mdbreact';

import QbotHelper from "../../helpers/qbotHelper";
import SpratSet from '../../components/components/spratset';
import Amplify, { Auth } from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import decode from 'jwt-decode';
import './list.css';
import io from "socket.io-client";
import {CSVLink} from "react-csv";

let loaded = false;

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  let d = Math.floor(seconds / (3600*24));
  let h = Math.floor(seconds % (3600*24) / 3600);
  let m = Math.floor(seconds % 3600 / 60);
  let s = Math.floor(seconds % 60);

  let dDisplay = d > 0 ? d + (d == 1 ? "d" : "d") : "";
  let hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
  let mDisplay = m > 0 ? m + (m == 1 ? "m" : "m") : "";
  let sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  if (d > 0) return dDisplay + ', ' + hDisplay;
  if (h > 0) return hDisplay;
  if (m > 0) return mDisplay;
  if (s > 0) return sDisplay;
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

const SignalList = () => {

  let tmpArray = [];
  tmpArray.fill(0, 0, 28);
  const [currencyPrices, setCurrencyPrices] = React.useState(tmpArray);
  

  const [authState, setAuthState] = React.useState();
  React.useEffect(() => {
    return onAuthUIStateChange(newAuthState => {
      if(newAuthState === 'signedin'){
        loadAccounts();
        loadSignals();
        loadSentiment();
      }
      setAuthState(newAuthState)
      const socket = io('https://wss3.live-rates.com/', {transports: ['websocket']});
      let key = 'e708c2d528'

      socket.on('connect', function() {
        
        // if you want to subscribe only specific instruments, emit instruments. To receive all instruments, comment the line below.
        let instruments = ['EURUSD','GBPUSD','USDCAD','EURGBP','USDCHF','USDJPY','GBPAUD','AUDUSD','NZDUSD','GBPJPY','AUDCAD','NZDCAD','GBPCHF','EURAUD','GBPCAD','CADJPY','EURCAD','EURNZD','EURCHF','GBPNZD','AUDNZD','AUDCHF','AUDJPY','CADCHF','CHFJPY','NZDCHF','NZDJPY','EURJPY']
        socket.emit('instruments', instruments);
        
        socket.emit('key', key); 
      });

      socket.on('rates', function(msg) {

        //Do what you want with the Incoming Rates... Enjoy!
        try {
          let obj = JSON.parse(msg);
            let digits = 5;
            if (QbotHelper.getCurrencyId(obj.currency) < 100) {
               if (obj.currency.includes("JPY")) {
                digits = 3;
               }
               currencyPrices[QbotHelper.getCurrencyId(obj.currency)] = obj.bid.toFixed(digits);
              setCurrencyPrices(prevCurrencyPrices => ([...currencyPrices]));
            }
        } catch (e) {
          console.log(msg)
        }

      });
    });
  }, []);

  const [buyDatatable, setBuyDatatable] = React.useState({
    columns: [
      {
        label: '',
        field: 'icon',
      },
      {
        label: 'Currency',
        field: 'currency',
      },
      {
        label: '1.25x Measure',
        field: 'sprat_zero_buy_level',
      },
      {
        label: '1.25x TP',
        field: 'sprat_zero_buy_tp',
      },
      {
        label: '1.25x Age',
        field: 'sprat_zero_buy_age',
      },

      {
        label: 'v7a Measure',
        field: 'sprat_v7a_buy_level',
      },
      {
        label: 'v7a TP',
        field: 'sprat_v7a_buy_tp',
      },
      {
        label: 'v7a Age',
        field: 'sprat_v7a_buy_age',
      },
      
      {
        label: '1.5x LVL',
        field: 'sprat_two_buy_level',
      },
      {
        label: '1.5x TP',
        field: 'sprat_two_buy_tp',
      },
      {
        label: '1.5x Age',
        field: 'sprat_two_buy_age',
      },
    ],
    
    rows: [],
  });

  const [sellDatatable, setSellDatatable] = React.useState({
    columns: [
      {
        label: '',
        field: 'icon',
      },
      {
        label: 'Currency',
        field: 'currency',
      },
      {
        label: '1.25x Measure',
        field: 'sprat_zero_sell_level',
      },
      {
        label: '1.25x TP',
        field: 'sprat_zero_sell_tp',
      },
      {
        label: '1.25x Age',
        field: 'sprat_zero_sell_age',
      },
      {
        label: 'v7a Measure',
        field: 'sprat_v7a_sell_level',
      },
      {
        label: 'v7a TP',
        field: 'sprat_v7a_sell_tp',
      },
      {
        label: 'v7a Age',
        field: 'sprat_v7a_sell_age',
      },
      {
        label: '1.5x LVL',
        field: 'sprat_two_sell_level',
      },
      {
        label: '1.5 TP',
        field: 'sprat_two_sell_tp',
      },
      {
        label: '1.5 Age',
        field: 'sprat_two_sell_age',
      },
    ],
    
    rows: [],
  });

  const [buyQb6Datatable, setBuyQb6Datatable] = React.useState({
    columns: [
      {
        label: '',
        field: 'icon',
      },
      {
        label: 'Currency',
        field: 'currency',
      },
      {
        label: 'QB6 Measure',
        field: 'sprat_qb6_buy_level',
      },
      {
        label: 'QB6 TP',
        field: 'sprat_qb6_buy_tp',
      },
      {
        label: 'QB6 Age',
        field: 'sprat_qb6_buy_age',
      },
    ],
    
    rows: [],
  });

  const [sellQb6Datatable, setSellQb6Datatable] = React.useState({
    columns: [
      {
        label: '',
        field: 'icon',
      },
      {
        label: 'Currency',
        field: 'currency',
      },
      {
        label: 'QB6 Measure',
        field: 'sprat_qb6_sell_level',
      },
      {
        label: 'QB6 TP',
        field: 'sprat_qb6_sell_tp',
      },
      {
        label: 'QB6 Age',
        field: 'sprat_qb6_sell_age',
      },
    ],
    
    rows: [],
  });

  const [signalDatatable, setSignalDatatable] = React.useState({
    columns: [
      {
        label: '',
        field: 'icon',
      },
      {
        label: 'Currency',
        field: 'currency',
      },
      {
        label: '#Buy',
        field: 'buysignals',
      },
      {
        label: '#Sell',
        field: 'sellsignals',
      },
      {
        label: 'BUY RMIADR 30M',
        field: 'buy_rmi_adr_30m',
      },
      {
        label: 'SELL RMIADR 30M',
        field: 'sell_rmi_adr_30m',
      },
      {
        label: 'BUY ADR M30',
        field: 'buy_adr_m30',
      },
      {
        label: 'SELL ADR M30',
        field: 'sell_adr_m30',
      },
      {
        label: 'BUY\nRMI\nM30',
        field: 'buy_rmi_m30',
      },
      {
        label: 'SELL RMI M30',
        field: 'sell_rmi_m30',
      },
      {
        label: 'BUY Trend M30',
        field: 'buy_trend_m30',
      },
      {
        label: 'SELL Trend M30',
        field: 'sell_trend_m30',
      },
      {
        label: 'BUY\nRMI\n1D',
        field: 'buy_rmi_1d',
      },
      {
        label: 'SELL RMI 1D',
        field: 'sell_rmi_1d',
      },
      {
        label: 'BUY SNIPER 1D',
        field: 'buy_sniper_1d',
      },
      {
        label: 'SELL SNIPER 1D',
        field: 'sell_sniper_1d',
      },
      {
        label: 'BUY AWR 1W',
        field: 'buy_awr_1w',
      },
      {
        label: 'SELL_ AWR 1W',
        field: 'sell_awr_1w',
      }
      
    ],
    
    rows: [],
  });

  const [sentimentDatatable, setSentimentDatatable] = React.useState({
    columns: [
      {
        label: '',
        field: 'icon',
      },
      {
        label: 'Currency',
        field: 'symbol',
      },
      {
        label: 'Heat',
        field: 'watch',
      },
      {
        label: 'Buy%',
        field: 'latest_long_percentage',
      },
      {
        label: 'Short%',
        field: 'latest_short_percentage',
      },

      {
        label: '4H Buy%',
        field: 'four_hour_long_percentage',
      },
      {
        label: '4H Short%',
        field: 'four_hour_short_percentage',
      },

      {
        label: '1D Buy%',
        field: 'one_day_long_percentage',
      },
      {
        label: '1D Short%',
        field: 'one_day_short_percentage',
      },
      {
        label: '1W Buy%',
        field: 'one_week_long_percentage',
      },
      {
        label: '1W Short%',
        field: 'one_week_short_percentage',
      },
      {
        label: 'Updated',
        field: 'updated',
      },
      
      
    ],
    
    rows: [],
  });
  
  let tmpBuyDatatable = JSON.parse(JSON.stringify(buyDatatable))
  let tmpSellDatatable = JSON.parse(JSON.stringify(sellDatatable))
  let tmpBuyQb6Datatable = JSON.parse(JSON.stringify(buyQb6Datatable))
  let tmpSellQb6Datatable = JSON.parse(JSON.stringify(sellQb6Datatable))
  let tmpSignalDatatable = JSON.parse(JSON.stringify(signalDatatable))
  let tmpSentimentDatatable = JSON.parse(JSON.stringify(sentimentDatatable))

  let ignoreAccounts = [31003101, 30693586];
  if (loaded === false){
    loaded = true;

    loadAccounts();
    loadSignals();
    loadSentiment();
    
  }
  
  function loadAccounts() {
    let currencies = [];
    for (let i=0;i<=28;i++) {
      currencies[i] = [];
      for (let x=0;x<=14;x++) {
        currencies[i][x] = [];
        for (let y=0;y<=2;y++) {
        currencies[i][x][y] = Object();
      }
      }
      
    }
    const tokens = Auth.currentSession().then(function(e){
      let u = e.getIdToken().payload['cognito:username'];
      let jwtToken = e.getIdToken().jwtToken;
      let currencyId = 0;
      let spratId = 0;
      let orderType = 0;
      let cognitoProfile = decode(jwtToken);
      fetch('https://botdashapi.fxsgnl.io/spratinfo', {
        headers: {
          "x-api-key": jwtToken
        },
      })
      .then((response) => response.json())
      .then((findresponse)=>{
        findresponse.sort((a,b) => b.current_level - a.current_level);
        tmpBuyDatatable.rows = findresponse;
        tmpSellDatatable.rows = findresponse;
        tmpBuyQb6Datatable.rows = findresponse;
        tmpSellQb6Datatable.rows = findresponse;

        for (let i=0;i<tmpBuyDatatable.rows.length;i++) {
          currencyId = QbotHelper.getCurrencyId(tmpBuyDatatable.rows[i].currency);
          spratId = tmpBuyDatatable.rows[i].sprat_id;
          orderType = tmpBuyDatatable.rows[i].order_type;
          currencies[currencyId][spratId][orderType] = tmpBuyDatatable.rows[i];
        }

        tmpBuyDatatable.rows = [];
        tmpSellDatatable.rows = [];
        tmpBuyQb6Datatable.rows = [];
        tmpSellQb6Datatable.rows = [];

        let rightNow = Math.floor(Date.now() / 1000);
        let age = 0;
        let digits = 5;
        for (let i=0;i<currencies.length;i++) {
          digits = 5;
          if(currencies[i][0][0].currency) {
            if (currencies[i][0][0].currency.includes("JPY")) {
              digits=3;
            }
          } 
          
          tmpBuyDatatable.rows[i] = Object();
          tmpBuyDatatable.rows[i].currency = currencies[i][0][0].currency;
          tmpBuyDatatable.rows[i].current_price = currencies[i][0][0].current_price;
          if (currencies[i][0][0].current_price) {
            currencyPrices[QbotHelper.getCurrencyId(currencies[i][0][0].currency)] = currencies[i][0][0].current_price.toFixed(digits);
          }

          age = rightNow-currencies[i][0][0].opentime;
          tmpBuyDatatable.rows[i].sprat_zero_buy_level  = currencies[i][0][0].current_level;
          tmpBuyDatatable.rows[i].sprat_zero_buy_tp     = currencies[i][0][0].take_profit;
          tmpBuyDatatable.rows[i].sprat_zero_buy_age    = secondsToDhms(age);
          tmpBuyDatatable.rows[i].days_since_global_spike = currencies[i][0][0].days_since_global_spike;
          tmpBuyDatatable.rows[i].days_since_spike      = currencies[i][0][0].days_since_spike;

          age = rightNow-currencies[i][0][1].opentime;
          tmpBuyDatatable.rows[i].sprat_zero_sell_level = currencies[i][0][1].current_level;
          tmpBuyDatatable.rows[i].sprat_zero_sell_tp    = currencies[i][0][1].take_profit;
          tmpBuyDatatable.rows[i].sprat_zero_sell_age    = secondsToDhms(age);
          
          age = rightNow-currencies[i][1][0].opentime;
          tmpBuyDatatable.rows[i].sprat_one_buy_level = currencies[i][1][0].current_level;
          tmpBuyDatatable.rows[i].sprat_one_buy_tp    = currencies[i][1][0].take_profit;
          tmpBuyDatatable.rows[i].sprat_one_buy_age    = secondsToDhms(age);

          age = rightNow-currencies[i][1][1].opentime;
          tmpBuyDatatable.rows[i].sprat_one_sell_level = currencies[i][1][1].current_level;
          tmpBuyDatatable.rows[i].sprat_one_sell_tp    = currencies[i][1][1].take_profit;
          tmpBuyDatatable.rows[i].sprat_one_sell_age    = secondsToDhms(age);

          age = rightNow-currencies[i][2][0].opentime;
          tmpBuyDatatable.rows[i].sprat_two_buy_level = currencies[i][2][0].current_level;
          tmpBuyDatatable.rows[i].sprat_two_buy_tp    = currencies[i][2][0].take_profit;
          tmpBuyDatatable.rows[i].sprat_two_buy_age    = secondsToDhms(age);

          age = rightNow-currencies[i][2][1].opentime;
          tmpBuyDatatable.rows[i].sprat_two_sell_level = currencies[i][2][1].current_level;
          tmpBuyDatatable.rows[i].sprat_two_sell_tp    = currencies[i][2][1].take_profit;
          tmpBuyDatatable.rows[i].sprat_two_sell_age    = secondsToDhms(age);

          age = rightNow-currencies[i][3][0].opentime;
          tmpBuyDatatable.rows[i].sprat_qb6_buy_level = currencies[i][3][0].current_level;
          tmpBuyDatatable.rows[i].sprat_qb6_buy_tp    = currencies[i][3][0].take_profit;
          tmpBuyDatatable.rows[i].sprat_qb6_buy_age    = secondsToDhms(age);

          age = rightNow-currencies[i][3][1].opentime;
          tmpBuyDatatable.rows[i].sprat_qb6_sell_level = currencies[i][3][1].current_level;
          tmpBuyDatatable.rows[i].sprat_qb6_sell_tp    = currencies[i][3][1].take_profit;
          tmpBuyDatatable.rows[i].sprat_qb6_sell_age    = secondsToDhms(age);

          age = rightNow-currencies[i][6][0].opentime;
          tmpBuyDatatable.rows[i].sprat_v7a_buy_level  = currencies[i][6][0].current_level;
          tmpBuyDatatable.rows[i].sprat_v7a_buy_tp     = currencies[i][6][0].take_profit;
          tmpBuyDatatable.rows[i].sprat_v7a_buy_age    = secondsToDhms(age);

          age = rightNow-currencies[i][6][1].opentime;
          tmpBuyDatatable.rows[i].sprat_v7a_sell_level = currencies[i][6][1].current_level;
          tmpBuyDatatable.rows[i].sprat_v7a_sell_tp    = currencies[i][6][1].take_profit;
          tmpBuyDatatable.rows[i].sprat_v7a_sell_age    = secondsToDhms(age);
          
        }

        tmpBuyDatatable.rows.sort((a,b) => b.sprat_v7a_buy_level - a.sprat_v7a_buy_level);
        tmpSellDatatable.rows = JSON.parse(JSON.stringify(tmpBuyDatatable.rows));
        tmpBuyQb6Datatable.rows = JSON.parse(JSON.stringify(tmpBuyDatatable.rows));
        tmpSellQb6Datatable.rows = JSON.parse(JSON.stringify(tmpBuyDatatable.rows));

        tmpSellDatatable.rows.sort((a,b) => b.sprat_v7a_sell_level - a.sprat_v7a_sell_level);
        tmpBuyQb6Datatable.rows.sort((a,b) => b.sprat_qb6_buy_level - a.sprat_qb6_buy_level);
        tmpSellQb6Datatable.rows.sort((a,b) => b.sprat_qb6_sell_level - a.sprat_qb6_sell_level);

        setBuyDatatable(tmpBuyDatatable);
        setSellDatatable(tmpSellDatatable);
        setBuyQb6Datatable(tmpBuyQb6Datatable);
        setSellQb6Datatable(tmpSellQb6Datatable);
        setCurrencyPrices(prevCurrencyPrices => ([...currencyPrices]));

        let intervalId = window.setTimeout(function(){
          console.log("Refresh time");
          loadAccounts();
        }, 30000);
        
      })
    });
  }

  function loadSignals() {
    let currencies = [];
    for (let i=0;i<29;i++) {
      currencies[i] = [];
      for (let x=0;x<=2;x++) {
        currencies[i][x] = [];
        for (let y=0;y<=200;y++) {
          currencies[i][x] = Object();
        }
      }
    }
    const tokens = Auth.currentSession().then(function(e){
      let u = e.getIdToken().payload['cognito:username'];
      let jwtToken = e.getIdToken().jwtToken;
      let cognitoProfile = decode(jwtToken);
      fetch('https://botdashapi.fxsgnl.io/signalinfo', {
        headers: {
          "x-api-key": jwtToken
        },
      })
      .then((response) => response.json())
      .then((findresponse)=>{
        
        findresponse.sort((a,b) => b.active - a.actve);
        tmpSignalDatatable.rows = findresponse;

        for (let i=0;i<tmpSignalDatatable.rows.length;i++) {
          currencies[QbotHelper.getCurrencyId(tmpSignalDatatable.rows[i].currency)][tmpSignalDatatable.rows[i].order_type][tmpSignalDatatable.rows[i].id] = tmpSignalDatatable.rows[i];
          tmpSignalDatatable.rows[i].order_type = tmpSignalDatatable.rows[i].order_type == 0 ? "BUY" : "SELL";
        }
        tmpSignalDatatable.rows = [];
        for (let x=0;x<currencies.length;x++) {
          tmpSignalDatatable.rows[x] = Object();
          tmpSignalDatatable.rows[x].buySignalsArray = [];
          tmpSignalDatatable.rows[x].sellSignalsArray = [];

          tmpSignalDatatable.rows[x].currency = currencies[x][0][6].currency;

          tmpSignalDatatable.rows[x].buy_rmi_adr_30m = currencies[x][0][6].active == 0 ? "-" : "YES";
          tmpSignalDatatable.rows[x].sell_rmi_adr_30m = currencies[x][1][6].active == 0 ? "-" : "YES";

          tmpSignalDatatable.rows[x].buy_rmi_m30= currencies[x][0][69].active == 0 ? "-" : "YES";
          tmpSignalDatatable.rows[x].sell_rmi_m30 = currencies[x][1][69].active == 0 ? "-" : "YES";
          
          tmpSignalDatatable.rows[x].buy_trend_m30= currencies[x][0][2].active == 0 ? "-" : "YES";
          tmpSignalDatatable.rows[x].sell_trend_m30 = currencies[x][1][2].active == 0 ? "-" : "YES";
          
          tmpSignalDatatable.rows[x].buy_rmi_1d= currencies[x][0][70].active == 0 ? "-" : "YES";
          tmpSignalDatatable.rows[x].sell_rmi_1d = currencies[x][1][70].active == 0 ? "-" : "YES";
          
          tmpSignalDatatable.rows[x].buy_sniper_1d= currencies[x][0][17].active == 0 ? "-" : "YES";
          tmpSignalDatatable.rows[x].sell_sniper_1d = currencies[x][1][17].active == 0 ? "-" : "YES";
          
          tmpSignalDatatable.rows[x].buy_awr_1w = currencies[x][0][102].active == 0 ? "-" : "YES";
          tmpSignalDatatable.rows[x].sell_awr_1w = currencies[x][1][102].active == 0 ? "-" : "YES";

          tmpSignalDatatable.rows[x].tradesignals = 0;

          tmpSignalDatatable.rows[x].buySignalsArray.push({signal: 2, active: currencies[x][0][2].active});
          tmpSignalDatatable.rows[x].buySignalsArray.push({signal: 6, active: currencies[x][0][6].active});
          tmpSignalDatatable.rows[x].buySignalsArray.push({signal: 12, active: currencies[x][0][102].active});
          tmpSignalDatatable.rows[x].buySignalsArray.push({signal: 17, active: currencies[x][0][17].active});
          tmpSignalDatatable.rows[x].buySignalsArray.push({signal: 69, active: currencies[x][0][69].active});
          tmpSignalDatatable.rows[x].buySignalsArray.push({signal: 70, active: currencies[x][0][70].active});

          tmpSignalDatatable.rows[x].sellSignalsArray.push({signal: 2, active: currencies[x][1][2].active});
          tmpSignalDatatable.rows[x].sellSignalsArray.push({signal: 6, active: currencies[x][1][6].active});
          tmpSignalDatatable.rows[x].sellSignalsArray.push({signal: 12, active: currencies[x][1][102].active});
          tmpSignalDatatable.rows[x].sellSignalsArray.push({signal: 17, active: currencies[x][1][17].active});
          tmpSignalDatatable.rows[x].sellSignalsArray.push({signal: 69, active: currencies[x][1][69].active});
          tmpSignalDatatable.rows[x].sellSignalsArray.push({signal: 70, active: currencies[x][1][70].active});

          tmpSignalDatatable.rows[x].buysignals =  (
            currencies[x][0][2].active + 
            currencies[x][0][6].active + 
            currencies[x][0][17].active +
            currencies[x][0][69].active +
            currencies[x][0][70].active +
            currencies[x][0][101].active +
            currencies[x][0][102].active);

          tmpSignalDatatable.rows[x].sellsignals =  (
            currencies[x][1][2].active +
            currencies[x][1][6].active + 
            currencies[x][1][17].active +
            currencies[x][1][69].active +
            currencies[x][1][70].active +
            currencies[x][1][101].active +
            currencies[x][1][102].active);
        }
        
        setSignalDatatable(tmpSignalDatatable);
      })
    });
  }

  function loadSentiment() {
 
    const tokens = Auth.currentSession().then(function(e){
      let u = e.getIdToken().payload['cognito:username'];
      let jwtToken = e.getIdToken().jwtToken;
      let cognitoProfile = decode(jwtToken);
      fetch('https://botdashapi.fxsgnl.io/myfxbook', {
        headers: {
          "x-api-key": jwtToken
        },
      })
      .then((response) => response.json())
      .then((findresponse)=>{

        var results = [];
        for(let i in findresponse[0]) {
          results.push([i,findresponse[0][i]]);
        }

        tmpSentimentDatatable.rows = [];
        for (let x=0;x<results.length;x++) {
          tmpSentimentDatatable.rows[x] = Object();
          tmpSentimentDatatable.rows[x].symbol = results[x][0];
          tmpSentimentDatatable.rows[x].latest_long_percentage = results[x][1]['latest'].long_percentage;
          tmpSentimentDatatable.rows[x].latest_short_percentage = results[x][1]['latest'].short_percentage;
          tmpSentimentDatatable.rows[x].updated = results[x][1]['latest'].timestamp + ' GMT';
          tmpSentimentDatatable.rows[x].watch = 0;

          if (results[x][1]['latest'].long_percentage >= 70 || results[x][1]['latest'].long_percentage <= 30) {
            tmpSentimentDatatable.rows[x].watch++;  
          } 

          if (results[x][1]['four_hours_ago']) {
            if (tmpSentimentDatatable.rows[x].watch > 0) {
              if (results[x][1]['latest'].long_percentage > 70 && results[x][1]['four_hours_ago'].long_percentage > results[x][1]['latest'].long_percentage) {
                tmpSentimentDatatable.rows[x].watch++;
              }

              if (results[x][1]['one_day_ago']) {
                if (results[x][1]['latest'].long_percentage > 70 && results[x][1]['four_hours_ago'].long_percentage > results[x][1]['one_day_ago'].long_percentage) {
                  tmpSentimentDatatable.rows[x].watch++;
                }
              }

              if (results[x][1]['latest'].short_percentage > 70 && results[x][1]['four_hours_ago'].short_percentage > results[x][1]['latest'].short_percentage) {
                tmpSentimentDatatable.rows[x].watch++;
              }

              if (results[x][1]['one_day_ago']) { 
                if (results[x][1]['latest'].short_percentage > 70 && results[x][1]['four_hours_ago'].short_percentage > results[x][1]['one_day_ago'].short_percentage) {
                  tmpSentimentDatatable.rows[x].watch++;
                }
              }
            }
            tmpSentimentDatatable.rows[x].four_hour_long_percentage = results[x][1]['four_hours_ago'].long_percentage;
            tmpSentimentDatatable.rows[x].four_hour_short_percentage = results[x][1]['four_hours_ago'].short_percentage;
          }
          
          if (results[x][1]['one_day_ago']) {
            tmpSentimentDatatable.rows[x].one_day_long_percentage = results[x][1]['one_day_ago'].long_percentage;
            tmpSentimentDatatable.rows[x].one_day_short_percentage = results[x][1]['one_day_ago'].short_percentage;  
          }
          
          if (results[x][1]['one_week_ago']) {
            tmpSentimentDatatable.rows[x].one_week_long_percentage = results[x][1]['one_week_ago'].long_percentage;
            tmpSentimentDatatable.rows[x].one_week_short_percentage = results[x][1]['one_week_ago'].short_percentage;
          }
        }
        
        tmpSentimentDatatable.rows.sort((a,b) => b.watch - a.watch);
        setSentimentDatatable(tmpSentimentDatatable);
      })
    });
  }

  const [checkbox1, setCheckbox1] = React.useState('');

  const showLogs2 = (e) => {
    setCheckbox1(e);
  };

  let signalCsvData = [];
  let sentimentCsvData = [];
  let today = new Date().toLocaleString();
  today = today.replace(',', '_');
  let signalCsvFilename = 'signals_download_'+today;
  let sentimentCsvFilename = 'sentiment_download_'+today;


  for (let i = 0; i < tmpSignalDatatable.rows.length; i++) {
    signalCsvData[i] = {
      'currency': tmpSignalDatatable.rows[i].currency,
      '#buy': tmpSignalDatatable.rows[i].buysignals,
      '#sell': tmpSignalDatatable.rows[i].sellsignals,
      'BUY RMIADR 30M': tmpSignalDatatable.rows[i].buy_rmi_adr_30m,
      'SELL RMIADR 30M': tmpSignalDatatable.rows[i].sell_rmi_adr_30m,
      'BUY ADR M30': tmpSignalDatatable.rows[i].buy_adr_m30,
      'SELL ADR M30': tmpSignalDatatable.rows[i].sell_adr_m30,
      'BUY RMI M30': tmpSignalDatatable.rows[i].buy_rmi_m30,
      'SELL RMI M30': tmpSignalDatatable.rows[i].sell_rmi_m30,
      'BUY Trend M30': tmpSignalDatatable.rows[i].buy_trend_m30,
      'SELL Trend M30': tmpSignalDatatable.rows[i].sell_trend_m30,
      'BUY RMI 1D': tmpSignalDatatable.rows[i].buy_rmi_1d,
      'SELL RMI 1D': tmpSignalDatatable.rows[i].sell_rmi_1d,
      'BUY SNIPER 1D': tmpSignalDatatable.rows[i].buy_sniper_1d,
      'SELL SNIPER 1D': tmpSignalDatatable.rows[i].sell_sniper_1d,
      'BUY AWR 1W': tmpSignalDatatable.rows[i].buy_awr_1w,
      'SELL_ AWR 1W': tmpSignalDatatable.rows[i].sell_awr_1w,
    }
  }

  for (let i = 0; i < tmpSentimentDatatable.rows.length; i++) {
    sentimentCsvData[i] = {
      'currency': tmpSentimentDatatable.rows[i].symbol,
      'heat': tmpSentimentDatatable.rows[i].watch,
      'Buy%': tmpSentimentDatatable.rows[i].latest_long_percentage,
      'Short%': tmpSentimentDatatable.rows[i].latest_short_percentage,
      '4H Buy%': tmpSentimentDatatable.rows[i].four_hour_long_percentage,
      '4H Short%': tmpSentimentDatatable.rows[i].four_hour_short_percentage,
      '1D Buy%': tmpSentimentDatatable.rows[i].one_day_long_percentage,
      '1D Short%': tmpSentimentDatatable.rows[i].one_day_short_percentage,
      '1W Buy%': tmpSentimentDatatable.rows[i].one_week_long_percentage,
      '1W Short%': tmpSentimentDatatable.rows[i].one_week_short_percentage,
      'Updated': tmpSentimentDatatable.rows[i].updated
    }
  }

  return (
    <div id='profile-v1'>
      <MDBContainer fluid id='v6' className='mb-5'>
          <div className="row">
            <div className="col-md-6">
              <h5 id="mtd_pound" className='pl-2 mt-4 text-left font-weight-bold white-text'>Buy Metrics (1.25 / 1.5 / v7a)</h5>
              <SpratSet sprats={tmpBuyDatatable.rows} ordertype="0" prices={currencyPrices} signals={signalDatatable} sentiment={sentimentDatatable}/>
            </div>
            <div className="col-md-6">
              <h5 id="mtd_pound" className='pl-2 mt-4 text-left font-weight-bold white-text'>Sell Metrics (1.25 / 1.5 / v7a) </h5>
              <SpratSet sprats={tmpSellDatatable.rows}  ordertype="1" prices={currencyPrices} signals={signalDatatable} sentiment={sentimentDatatable}/>
            </div>
          </div>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBCard narrow className='pb-3'>
          <MDBView
            cascade
            className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
          >
            <div className='text-left'>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='th-large' className='mt-0' />
              </MDBBtn>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='columns' className='mt-0' />
              </MDBBtn>
            </div>
            <a href='#!' className='white-text mx-3'>
              Signal List
            </a>
            <div className='text-right'>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='info-circle' className='mt-0' />
              </MDBBtn>
            </div>
          </MDBView>
          <MDBCardBody>
            <CSVLink class='btn btn-primary' data={signalCsvData} filename={signalCsvFilename} >
              <MDBIcon far icon="file-excel" className="pr-1" />
              Export to CSV
            </CSVLink>
            <MDBDataTableV5
              order={['drawdown_percent', 'desc' ]}
              responsive
              striped
              hover
              entriesOptions={[5,15,25]}
              entries={5}
              pagesAmount={4}
              data={signalDatatable}
              headCheckboxID='id41'
              bodyCheckboxID='checkboxes41'
              getValueCheckboxes={(e) => {
                showLogs2(e);
              }}
              getValueAllCheckBoxes={(e) => {
                showLogs2(e);
              }}
              multipleCheckboxes
              proCheckboxes
              filledCheckboxes
              proSelect
              paging={false}
              searchTop
              searchBottom={false}
            />
          </MDBCardBody>
        </MDBCard>

        <MDBCard narrow className='pb-3'>
          <MDBView
            cascade
            className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
          >
            <div className='text-left'>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='th-large' className='mt-0' />
              </MDBBtn>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='columns' className='mt-0' />
              </MDBBtn>
            </div>
            <a href='#!' className='white-text mx-3'>
              Sentiment List
            </a>
            <div className='text-right'>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='info-circle' className='mt-0' />
              </MDBBtn>
            </div>
          </MDBView>
          <MDBCardBody>
            <CSVLink class='btn btn-primary' data={sentimentCsvData} filename={sentimentCsvFilename} >
              <MDBIcon far icon="file-excel" className="pr-1" />
              Export to CSV
            </CSVLink>
            <MDBDataTableV5
              order={['watch', 'desc' ]}
              responsive
              striped
              hover
              entriesOptions={[5,15,25]}
              entries={5}
              pagesAmount={4}
              data={sentimentDatatable}
              
              headCheckboxID='id41'
              bodyCheckboxID='checkboxes41'
              getValueCheckboxes={(e) => {
                showLogs2(e);
              }}
              getValueAllCheckBoxes={(e) => {
                showLogs2(e);
              }}
              multipleCheckboxes
              proCheckboxes
              filledCheckboxes
              proSelect
              paging={false}
              searchTop
              searchBottom={false}
            />
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
};

export default SignalList;
