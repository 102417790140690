import React from 'react';
import {
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBView,
  MDBDataTableV5
} from 'mdbreact';

import QbotHelper from "../../helpers/qbotHelper";

import StackSet from '../../components/components/stackset';
import Amplify, { Auth } from 'aws-amplify';
import { onAuthUIStateChange } from '@aws-amplify/ui-components'

import decode from 'jwt-decode';
import './list.css';
import $ from 'jquery';
import qbotHelper from "../../helpers/qbotHelper";
import {CSVLink} from "react-csv";

let loaded = false;

function secondsToDhms(seconds) {
  seconds = Number(seconds+(3600*3));
  var d = Math.floor(seconds / (3600*24));
  var h = Math.floor(seconds % (3600*24) / 3600);
  var m = Math.floor(seconds % 3600 / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? "d" : "d") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? "h" : "h") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? "m" : "m") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  if (d > 0) return dDisplay + ', ' + hDisplay;
  if (h > 0) return hDisplay + ', ' + mDisplay;
  if (m > 0) return mDisplay;
  if (s > 0) return sDisplay;
  return dDisplay + hDisplay + mDisplay + sDisplay;
}



const StackList = () => {

  $(() => $('#refresh').unbind());
  $(() => $('#refresh').click(function(){
    loadStacks();
  }));

  const [totalPnl, setTotalPnl] = React.useState();

  const [authState, setAuthState] = React.useState();
  React.useEffect(() => {
    return onAuthUIStateChange(newAuthState => {
      if(newAuthState === 'signedin'){
        loadStacks();
        
      }
      setAuthState(newAuthState)
    });
  }, []);

  const [fallbackBoysStatus, setFallbackBoysStatus] = React.useState('Off');

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: '',
        field: 'icon',
      },
      {
        label: 'Currency',
        field: 'currency',
      },
      {
        label: 'Buy/Sell',
        field: 'buy_sell',
      },
      {
        label: 'Volume',
        field: 'volume',
      },
      {
        label: 'Measure',
        field: 'current_level',
      },
      {
        label: 'Open Price',
        field: 'open_price',
      },
      {
        label: 'Current Price',
        field: 'current_price',
      },

      {
        label: 'TP',
        field: 'take_profit',
      },
      {
        label: 'Profit',
        field: 'profit',
      },

      {
        label: 'Stack P&L',
        field: 'stack_pnl',
      },
      {
        label: 'Total P&L',
        field: 'total_pnl',
      },
      {
        label: 'Algo',
        field: 'qbot',
      },
    ],
    rows: [],
  });

  
  let tmpDatatable = JSON.parse(JSON.stringify(datatable))
  let ignoreAccounts = [31003101, 30693586];
  if (loaded == false){
    loaded = true;
    loadStacks();
  }
  
  function loadStacks() {

    const urlParams = new URLSearchParams(window.location.search);
    let accountId = urlParams.get('account');
    let symbol = urlParams.get('symbol');
    if (!accountId) {
      accountId = 208361;
    }
    
    const tokens = Auth.currentSession().then(function(e){
      let u = e.getIdToken().payload['cognito:username'];
      let jwtToken = e.getIdToken().jwtToken;
      let currencyId = 0;
      let spratId = 0;
      let orderType = 0;
      let gap = 0;
      let cognitoProfile = decode(jwtToken);
      let url = 'https://botdashapi.fxsgnl.io/trades?account=' + accountId;

      if (symbol !== null) {
        url += '&symbol='+symbol
      }
      fetch(url, {
        headers: {
          "x-api-key": jwtToken
        },
      })
      .then((response) => response.json())
      .then((findresponse)=>{
        tmpDatatable.rows = findresponse;
        if (!tmpDatatable.rows) {
          tmpDatatable.rows = [];
        }

        if (findresponse.length > 0) {
          if (findresponse[0].fallbackboys_engaged === true) {
            setFallbackBoysStatus('On');
          }
        }

        let rightNow = Math.floor(Date.now() / 1000);
        let age = 0;
        let tmpTotalPnl = 0;

        let stackId = "";
        let prevStackId = "";
        let tmpPnlPc=0;
        for (let i=0;i<tmpDatatable.rows.length;i++) {
          stackId =  tmpDatatable.rows[i].magic + '' + tmpDatatable.rows[i].order_type + '' + tmpDatatable.rows[i].currency;
          currencyId = qbotHelper.getCurrencyId(tmpDatatable.rows[i].currency);
          spratId = tmpDatatable.rows[i].sprat_id;
          orderType = tmpDatatable.rows[i].order_type;
          age = rightNow-tmpDatatable.rows[i].open_time;

          if (orderType == 0) {
            gap = tmpDatatable.rows[i].take_profit - tmpDatatable.rows[i].current_price;
          } else {
            gap = tmpDatatable.rows[i].current_price - tmpDatatable.rows[i].take_profit;
          }

          if (true == tmpDatatable.rows[i].currency.includes("JPY")) {
            gap = gap * 100;
          } else {
            gap = gap * 10000;
          }

          tmpDatatable.rows[i].buy_sell = tmpDatatable.rows[i].order_type == 0 ? "BUY" : "SELL";
          tmpDatatable.rows[i].qbot = qbotHelper.getQBotName(tmpDatatable.rows[i].magic);
          tmpDatatable.rows[i].gap = Math.round(gap);
          tmpDatatable.rows[i].age = secondsToDhms(age);
          tmpTotalPnl = Math.round((tmpTotalPnl + tmpDatatable.rows[i].profit)*100)/100;
          console.log("tmp: " + tmpTotalPnl);
   
          tmpPnlPc =(tmpTotalPnl/17500*100).toFixed(2) + '%';
          setTotalPnl(tmpTotalPnl + ' (' + tmpPnlPc + ')');
        }

        for (let i=0;i<tmpDatatable.rows.length;i++) {
          tmpDatatable.rows[i].total_pnl = tmpTotalPnl;
        }
        
        setDatatable(tmpDatatable);
        var intervalId = window.setTimeout(function(){
          loadStacks();
        }, 30000);
      })
    });
  }

  const [checkbox1, setCheckbox1] = React.useState('');

  const showLogs2 = (e) => {
    setCheckbox1(e);
  };

  const urlString = qbotHelper.getFallbackOnlyUrl();

  let stackCsvData = [];
  let today = new Date().toLocaleString();
  today = today.replace(',', '_');
  let stackCsvFilename = 'stacks_download_'+today;

  if (loaded) {
    for (let i = 0; i < tmpDatatable.rows.length; i++) {
      stackCsvData[i] = {
        'currency': tmpDatatable.rows[i].currency,
        'buy/sell': tmpDatatable.rows[i].buy_sell,
        'volume': tmpDatatable.rows[i].volume,
        'measure': tmpDatatable.rows[i].current_level,
        'open price': tmpDatatable.rows[i].open_price,
        'current price': tmpDatatable.rows[i].current_price,
        'TP': tmpDatatable.rows[i].take_profit,
        'profit': tmpDatatable.rows[i].profit,
        'Stack P&L': tmpDatatable.rows[i].stack_pnl,
        'Total P&L': totalPnl,
        'Algo': tmpDatatable.rows[i].qbot,
      }
    }
  }

  return (
    <div id='profile-v1'>
      <MDBContainer fluid id='v6'>
        <h5 id="mtd_pound" className='mt-4 text-left font-weight-bold white-text'>Global Position: &pound;{totalPnl}</h5>
        <h5 className='mt-4 text-left white-text'>
          Fallback is currently: <strong className='font-weight-bold'><a href={urlString}>{fallbackBoysStatus}</a></strong>
        </h5>
      </MDBContainer>
      <MDBContainer fluid id='v6' className='mb-5'>
        <section className='mb-4'>
          <MDBRow>
            <StackSet stacks={datatable.rows} />
          </MDBRow>
        </section>
      </MDBContainer>
      <MDBContainer fluid>
        <MDBCard narrow className='pb-3'>
          <MDBView
            cascade
            className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
          >
            <div className='text-left'>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='th-large' className='mt-0' />
              </MDBBtn>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon icon='columns' className='mt-0' />
              </MDBBtn>
            </div>
            <a href='#!' className='white-text mx-3'>
              Stacks
            </a>
            <div className='text-right'>
              <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                <MDBIcon id="refresh" icon='info-circle' className='mt-0' />
              </MDBBtn>
            </div>
          </MDBView>
          <MDBCardBody>
            <CSVLink class='btn btn-primary' data={stackCsvData} filename={stackCsvFilename} >
              <MDBIcon far icon="file-excel" className="pr-1" />
              Export to CSV
            </CSVLink>
            <MDBDataTableV5
              order={['drawdown_percent', 'desc' ]}
              responsive
              striped
              hover
              entriesOptions={[5,15,25]}
              entries={5}
              pagesAmount={4}
              data={datatable}
              headCheckboxID='id41'
              bodyCheckboxID='checkboxes41'
              getValueCheckboxes={(e) => {
                showLogs2(e);
              }}
              getValueAllCheckBoxes={(e) => {
                showLogs2(e);
              }}
              multipleCheckboxes
              proCheckboxes
              filledCheckboxes
              proSelect
              paging={false}
              searchTop
              searchBottom={false}
            />
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
    </div>
  );
};

export default StackList;
