import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBChip,
  MDBBadge,
  MDBIcon,
  MDBCard,
} from 'mdbreact';
import QbotHelper from "../../helpers/qbotHelper";
import './stackset.css';
import Stack from '../../components/components/stack';

class StackSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // No avatars:
      
    };
  }

  render() {
    //const { stacks } = this.props.stacks;
    const { currencies } = {EURUSD: [],GBPUSD: [],AUDUSD: [],AUDCAD: [],USDJPY: [],EURCHF: [],USDCHF: [],GBPNZD: [],EURCAD: [],USDCAD: [],AUDNZD: [],AUDCHF: [],AUDJPY: [],CADCHF: [],GBPJPY: [],CHFJPY: [],EURAUD: [],NZDCHF: [],GBPAUD: [],CADJPY: [],NZDCAD: [],EURGBP: [],EURJPY: [],GBPCHF: [],NZDUSD: [],GBPCAD: []};
    let stack = [];
    let nxtItem = 0;

    for (let i=0;i<this.props.stacks.length;i++) {

      
      if (undefined === stack[this.props.stacks[i].magic]) {
        stack[this.props.stacks[i].magic] = {};
        stack[this.props.stacks[i].magic] = {settings: {name: QbotHelper.getQBotName(this.props.stacks[i].magic)}, trades: []};
        stack[this.props.stacks[i].magic]['trades'][0] = {};
        stack[this.props.stacks[i].magic]['trades'][1] = {};
      }

      if (undefined === stack[this.props.stacks[i].magic]['trades'][this.props.stacks[i].order_type]) {
        stack[this.props.stacks[i].magic]['trades'][this.props.stacks[i].order_type] = currencies;
      }

      if (undefined === stack[this.props.stacks[i].magic]['trades'][this.props.stacks[i].order_type][this.props.stacks[i].currency]) {
        stack[this.props.stacks[i].magic]['trades'][this.props.stacks[i].order_type][this.props.stacks[i].currency] = [];
      }

      this.props.stacks[i].color = (this.props.stacks[i].stack_pnl > 0) ? { color: 'green' } : { color: 'red' };
      stack[this.props.stacks[i].magic]['trades'][this.props.stacks[i].order_type][this.props.stacks[i].currency].push(this.props.stacks[i]);

      this.props.stacks[i].global_dd_pc = (this.props.stacks[i].stack_pnl / 17500 *100).toFixed(2);
      this.props.stacks[i].link = "/accounts/journal?magic="+this.props.stacks[i].magic +'&symbol=' + this.props.stacks[i].currency
    }

    let stacks = [];
    for (let x=0;x<stack.length;x++) {
      if (stack[x]) {
        for (let y=0;y<2;y++) {
          for (const property in stack[x]['trades'][y]) {
            if(stack[x]['trades'][y][property]) {
              stacks.push(stack[x]['trades'][y][property]);
            }
          }
        }
      }
    }

    stacks.sort((a,b) => a[0].gap - b[0].gap);

   

    const hStyle = { color: 'red' };


    return stacks.map((item, index) => (
        <MDBCard className="m-2 stackSet">
          <MDBRow className='w-100 pl-3'>
            <MDBCol col="2" className='text-left'>
              <h5 id="mtd_pound" className='mt-4  font-weight-bold white-text'><a href={item[0].link}>{item[0].currency}</a><br/>TP in {item[0].gap}</h5>
              <p className='font-small grey-text'>{item[0].qbot} - {item[0].buy_sell}</p>
            </MDBCol>
            <MDBCol col="2" className='text-right'>
              <h5 id="mtd_pound" className='mt-4  font-weight-bold white-text'><span style={ item[0].color }>{item[0].global_dd_pc}%</span><br/><span>{item[0].take_profit}</span></h5>
              <p className='font-small grey-text'>{item[0].age}</p>
            </MDBCol>
          </MDBRow>
          <MDBRow className='w-100 pl-4'>
            <MDBCol className='text-left'>
              <Stack key={index} stack={item} />
            </MDBCol>
          </MDBRow>
        </MDBCard>
    ));
  }
}

export default StackSet;
