import React from 'react';
import {MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput} from 'mdbreact';


class FilterByForm extends React.Component {
    constructor(props) {
        super(props);
        let excluded_cats = [];
        let allChecked = false;
        if (this.props.excluded_cats !== null) {
            excluded_cats =  this.props.excluded_cats.split(',');
            if (excluded_cats.length === this.props.categories.length) {
                allChecked = true;
            }
        }

        // these values will have been set based on the query string params from the parent component
        this.state = {
            checked_cats: (excluded_cats.length > 0) ? excluded_cats : [],
            selected_magic: (null === this.props.selected_magic) ? 'ALL' : this.props.selected_magic,
            selected_symbol: (null === this.props.selected_symbol) ? 'All' : this.props.selected_symbol,
            allCheck: allChecked
        }

        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMagicSelect = this.handleMagicSelect.bind(this);
        this.handleSymbolSelect = this.handleSymbolSelect.bind(this);
        this.handleCheckUncheckAll = this.handleCheckUncheckAll.bind(this);
    }

    handleSymbolSelect(event) {
        this.setState({
            selected_symbol : event.target.value
        }, () => {
            let selected_symbol = this.state.selected_symbol;
            this.handleSubmit(selected_symbol)
        });
    }

    handleCheckbox(event) {
        // add a clicked checkbox value into the array of selected categories or remove it if its being unchecked
        let catArray = [...this.state.checked_cats];

        if (event.target.checked) {
            // click on
            this.setState({
                checked_cats: [...this.state.checked_cats, event.target.value]
            }, () => {
                let checked_cats = this.state.checked_cats;
                this.handleSubmit(checked_cats)
            })

        } else {
            // click off
            let index = catArray.indexOf(event.target.value);
            if (index > -1) {
                catArray.splice(index, 1);
                this.setState({
                   checked_cats: catArray
                }, () => {
                    this.setState({allCheck: false}, () => {
                        let checked_cats = this.state.checked_cats;
                        this.handleSubmit(checked_cats)
                    });
                });
            }
        }
    }

    handleCheckUncheckAll(event) {
        let allChecked = this.state.allCheck;

        if (false === allChecked) {
            this.setState({allCheck: true}, () => {
                let catArray = [];
                for (let i = 0; i < this.props.categories.length; i++) {
                    catArray[i] = this.props.categories[i].name;
                }
                this.setState({checked_cats: catArray}, () => {
                    this.handleSubmit();
                });

            });
        } else {
            this.setState({allCheck: false}, () => {
                this.setState({checked_cats: []}, () => {
                    this.handleSubmit();
                });

            })
        }
    }

    handleMagicSelect(event) {
        this.setState({
            selected_magic: event.target.value
        }, () => {
            this.handleSubmit()
        });
    }
    /**
     *  constructs a query string from the selected form element states and then redirects back to itself
     *  so that the parent can make the http request
     */
    handleSubmit() {
        let categories = [];
        let catString = null;
        if (this.state.checked_cats.length > 0) {
            categories = [...this.state.checked_cats];
            catString = categories.join(",");
        }
        const magicFilter = this.state.selected_magic;
        const symbolFilter = this.state.selected_symbol;
        let newUrl = window.location.protocol + "//" + window.location.host+'/accounts/journal?';

        if (null !== magicFilter) {
            if (magicFilter === 'ALL') {
                newUrl = newUrl.replace('&magic='+magicFilter, '' );
                window.history.pushState({path:newUrl},'',newUrl);
            } else {
                window.history.pushState({path: newUrl}, '', newUrl + '&magic=' + magicFilter);
                newUrl = newUrl + '&magic=' + magicFilter;
            }
        }

        if (null !== catString) {
            window.history.pushState({path:newUrl},'',newUrl+'&excluded_cats='+catString);
            newUrl += '&exclude_categories='+catString;
        }

        if (null !== symbolFilter) {
            if (symbolFilter === 'All') {
                newUrl = newUrl.replace('&symbol='+symbolFilter, '' );
                window.history.pushState({path:newUrl},'',newUrl);
            } else {
                window.history.pushState({path:newUrl},'',newUrl+'&symbol='+symbolFilter);
            }
        }

        this.props.loadJournalEntriesFunction();
    }

    render () {
        return (
            <section>
                <form onSubmit={this.handleSubmit}>
                    <MDBCard className='mb-5'>
                        <MDBCardBody>
                            <MDBCol className="col-12 row">
                                <h5>Exclude These Categories</h5>
                                <MDBInput
                                    label='Check / Uncheck All'
                                    type='checkbox'
                                    id="check_all"
                                    name="check_all"
                                    onChange={this.handleCheckUncheckAll}
                                    containerClass='col-md-3 col-sm-12'
                                    checked={this.state.allCheck}
                                />
                            </MDBCol>
                            <MDBCol md='12' lg='12' >
                                <div className='row'>
                                    {this.props.categories.map(cat => {
                                       return <MDBInput
                                            label={cat.name}
                                            key={cat.name}
                                            type='checkbox'
                                            id={cat.name}
                                            name={cat.name}
                                            onChange={this.handleCheckbox}
                                            value={cat.name}
                                            checked={this.state.checked_cats.includes(cat.name) || this.state.allCheck}
                                            containerClass='col-md-3 col-sm-12'
                                        />
                                    })}
                                </div>
                            </MDBCol>
                            <MDBRow>
                                <MDBCol md='12' lg='4' className='mb-4'>
                                <label>
                                    Select a Magic
                                </label>
                                <select onChange={this.handleMagicSelect}
                                        className="form-control col-12" id="magicSelect"
                                        value={this.state.selected_magic}
                                >
                                    {this.props.magics.map( magic => {
                                        return <option value={magic.id} key={magic.id}>
                                            {magic.name}
                                        </option>
                                    })}
                                </select>
                                </MDBCol>
                                <MDBCol md='12' lg='4' className='mb-4'>
                                    <label>
                                        Select a Symbol
                                    </label>
                                    <select
                                        onChange={this.handleSymbolSelect}
                                        className="form-control col-12 "
                                        defaultValue={this.state.selected_symbol}
                                        id="magicSelect"
                                    >
                                        {this.props.symbols.map(symbol => {
                                            return <option value={symbol.name} key={symbol.name}>
                                                {symbol.name}
                                            </option>
                                        })}
                                    </select>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </form>
            </section>
        )
    }
}
export default FilterByForm;