/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:174c52c8-312d-47db-a7d0-dca9e262b7e2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qVGFB7H01",
    "aws_user_pools_web_client_id": "4t6ev1g7p80qqqqapfsij9dv5r",
    "oauth": {}
};


export default awsmobile;
