import React from "react";
export default class TradingView extends React.Component {
    constructor(props) {
        super(props);
        this.reference = React.createRef();
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-events.js";
        script.async = true;
        script.text = '{"colorTheme": "dark", "isTransparent": true, "width": "100%", "height": "100%", "locale": "en","importanceFilter": "0,1"}';

        this.reference.appendChild(script);
    }

    render() {
        return (
            <div style={{height: 100+'%'}} ref={DOMNodeRef => {this.reference = DOMNodeRef;}}></div>
        );
    }
}