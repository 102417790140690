import React from 'react';
import {MDBBtn, MDBDatePicker, MDBIcon, MDBRow} from "mdbreact";


class FilterDateForm extends React.Component {

    constructor(props) {
        super(props);
        this.submitEvent = this.props.submitEvent.bind(this);
        this.handleFromDate = this.handleFromDate.bind(this);
        this.handleToDate = this.handleToDate.bind(this);
        this.state = {
            fromDate: this.props.fromDate,
            toDate: this.props.toDate
        };
    }

    handleFromDate = (value) => {
        this.setState({fromDate: value})
    }

    handleToDate = (value) => {
        this.setState({toDate: value})
    }

    handleSubmit = () => {
        this.submitEvent(this.state.fromDate, this.state.toDate);
    }

    render() {
        return (
            <div>
                <MDBRow>
                    <div className='col-6 offset-1 row'>
                        <div>
                            {this.props.fromDateText}
                            <MDBDatePicker data-provide="datepicker" format='YYYY-MM-DD' date={this.state.fromDate} getValue={this.handleFromDate} />
                        </div>
                        <div className="ml-5">
                            {this.props.toDateText}
                            <MDBDatePicker format='YYYY-MM-DD' date={this.state.toDate} getValue={this.handleToDate}/>
                        </div>
                    </div>
                </MDBRow>
                <MDBRow>
                    <div className='col-6 offset-1 row'>
                    <MDBBtn color='green' type='submit' onClick={this.handleSubmit}>
                        <MDBIcon icon='check' className='mr-2'  />
                        Submit
                    </MDBBtn>
                    </div>
                </MDBRow>
            </div>
        )
    }
}

export default FilterDateForm;