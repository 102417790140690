
const qBotHelper = {

    minutesBreakdown: function(minutes) {
        let seconds = parseInt(minutes, 10);
        let days = Math.floor(seconds / (3600*24));
        seconds  -= days*3600*24;
        let hrs   = Math.floor(seconds / 3600);
        seconds  -= hrs*3600;
        let mnts = Math.floor(seconds / 60);
        seconds  -= mnts*60;
        let agoString = '';

        if (days > 0) {
            agoString += days+" days ";
        }
        agoString += hrs+" Hrs, "+mnts+" Minutes, "+seconds+" Seconds";
        return agoString;
    },

    getCurrencyId: function(currencyString) {

        switch (currencyString) {
            case "EURUSD":
                return 0;
            case "GBPUSD":
                return 1;
            case "USDCAD":
                return 2;
            case "EURGBP":
                return 3;
            case "USDCHF":
                return 4;
            case "USDJPY":
                return 5;
            case "GBPAUD":
                return 6;
            case "AUDUSD":
                return 7;
            case "NZDUSD":
                return 8;
            case "GBPJPY":
                return 9;
            case "AUDCAD":
                return 10;
            case "NZDCAD":
                return 11;
            case "GBPCHF":
                return 12;
            case "EURAUD":
                return 13;
            case "GBPCAD":
                return 14;
            case "CADJPY":
                return 15;
            case "EURCAD":
                return 16;
            case "EURNZD":
                return 17;
            case "EURCHF":
                return 18;
            case "GBPNZD":
                return 19;
            case "AUDNZD":
                return 20;
            case "AUDCHF":
                return 21;
            case "AUDJPY":
                return 22;
            case "CADCHF":
                return 23;
            case "CHFJPY":
                return 24;
            case "NZDCHF":
                return 25;
            case "NZDJPY":
                return 26;
            case "EURJPY":
                return 27;
            case "XTIUSD":
                return 28;
            default:
                return 100;
        }
    },


    // Return an array of all symbols, sorted by name alphabetically
    getSymbolArray: function(excludeAllOption = false) {
        let symbols  = [
            {name: "All", id: 'ALL'},
            {name: "EURUSD", id: 'EURUSD'},
            {name: "GBPUSD", id: "GBPUSD"},
            {name: 'USDCAD', id: "USDCAD"},
            {name: 'EURGBP', id: "EURGBP"},
            {name: 'USDCHF', id: "USDCHF"},
            {name: 'USDJPY', id: "USDJPY"},
            {name: 'GBPAUD', id: "GBPAUD"},
            {name: 'AUDUSD', id: "AUDUSD"},
            {name: 'NZDUSD', id: "NZDUSD"},
            {name: 'GBPJPY', id: "GBPJPY"},
            {name: 'AUDCAD', id: "AUDCAD"},
            {name: 'NZDCAD', id: "NZDCAD"},
            {name: 'GBPCHF', id: "GBPCHF"},
            {name: 'EURAUD', id: "EURAUD"},
            {name: 'GBPCAD', id: "GBPCAD"},
            {name: 'CADJPY', id: "CADJPY"},
            {name: 'EURCAD', id: "EURCAD"},
            {name: 'EURNZD', id: "EURNZD"},
            {name: 'EURCHF', id: "EURCHF"},
            {name: 'GBPNZD', id: "GBPNZD"},
            {name: 'AUDNZD', id: "AUDNZD"},
            {name: 'AUDCHF', id: "AUDCHF"},
            {name: 'AUDJPY', id: "AUDJPY"},
            {name: 'CADCHF', id: "CADCHF"},
            {name: 'CHFJPY', id: "CHFJPY"},
            {name: 'NZDCHF', id: "NZDCHF"},
            {name: 'NZDJPY', id: "NZDJPY"},
            {name: 'EURJPY', id: "EURJPY"},
            {name: 'XTIUSD', id: "XTIUSD"},
        ];

        // remove the all option
        if (true === excludeAllOption) {
            symbols.shift();
        }

        return symbols.sort((a, b) => a.name.localeCompare(b.name))
    },

    getQbotArray: function() {
        return [
            {name: "All", id:'ALL'},
            {name: "1.25 Sprat", id: "0" },
            {name: "1.41 Sprat", id: "1" },
            {name: '1.5 Sprat', id: "2" },
            {name: 'QB6 Sprat', id: "3" },
            {name: 'QB7 Sprat', id: "4" },
            {name: 'Sniper Sprat', id: "5" },
            {name: 'v7a Sprat', id: "6" },
            {name: 'v7d Sprat', id: "7" },
            {name: 'LongLegs Sprat', id: "8" },
            {name: 'Crude Oil', id: "9" },
            {name: 'QB5A', id: "20005" },
            {name: 'QB5B', id: "200052" },
            {name: 'QB5C', id: "200053" },
            {name: 'QB6A', id: "20006" },
            {name: 'QB6B', id: "200062" },
            {name: 'QB6C', id: "200063" },
            {name: 'QB7A', id: "20007" },
            {name: 'QB7B', id: "200072" },
            {name: 'QB7C', id: "200073" },
            {name: 'QB7D', id: "200074" },
            {name: 'QB8', id: "20008" },
            {name: '1.25OG', id: "10000" }
        ]
    },

    getQBotName: function(magicId) {
        switch(magicId) {
            case 0:
                return "1.25 Sprat"
            case 1:
                return "1.41 Sprat"
            case 2:
                return "1.5 Sprat"
            case 3:
                return "QB6 Sprat"
            case 4:
                return "QB7 Sprat"
            case 5:
                return "Sniper Sprat"
            case 6:
                return "v7a Sprat"
            case 7:
                return "v7d Sprat"
            case 8:
                return "LongLegs Sprat"
            case 9:
                return "Crude Oil"
            case 20005:
                return "QB5A";
            case 200052:
                return "QB5B";
            case 200053:
                return "QB5C";
            case 20006:
                return "QB6A";
            case 200062:
                return "QB6B";
            case 200063:
                return "QB6C";
            case 20007:
                return "QB7A";
            case 200072:
                return "QB7B";
            case 200073:
                return "QB7C";
            case 200074:
                return "QB7D";
            case 20008:
                return "QB8";
            case 10000:
                return "1.25OG";
            default:
                return "MAN";
            }
        },

    // return an array of categories, sorted by name alphabetically
    getCategories: function() {
        const categories =  [
            {name: "abort"},
            {name: "atrspike"},
            {name: 'attempt'},
            {name: 'bulkhours'},
            {name: 'chameleon'},
            {name: 'checkstacktp'},
            {name: 'clutch'},
            {name: 'currencyrisk'},
            {name: 'fallbackboys'},
            {name: 'globalspike'},
            {name: 'init'},
            {name: 'leveldelay'},
            {name: 'limit'},
            {name: 'macktp'},
            {name: 'newtrade'},
            {name: 'oldstack'},
            {name: 'qsb'},
            {name: 'retraced'},
            {name: 'solostack'},
            {name: 'speedbulk'},
            {name: 'spratcheck'},
            {name: 'spread'},
            {name: 'spreadcheck'},
            {name: 'startlot'},
            {name: 'time'},
            {name: 'cryo'},
            {name: 'settings'},
        ]

        return categories.sort((a, b) => a.name.localeCompare(b.name));
    },

    getFallbackOnlyUrl: function() {
        let urlString = 'journal?exclude_categories=';
        let categories = this.getCategories();
        let filtered = categories.filter(function(value, index, arr){
            return value.name !== 'fallbackboys';
        });
        // just making sure we don't have a trailing comma on the url
        for (let i = 0; i < filtered.length; i++) {
            urlString += filtered[i].name;
            if (i + 1 < filtered.length) {
                urlString += ',';
            }
        }

        return urlString;
    },

    getTimeZone: function(hour) {
        switch (true) {
            case(hour >= 23 || (hour >= 0 && hour < 6 )) :
                return "TOKYO_SYDNEY"
            case(hour >= 21) :
                return 'SYDNEY ONLY'
            case(hour >= 16) :
                return "NEW YORK ONLY"
            case(hour >= 12) :
                return "LONDON_NEWYORK"
            case(hour >= 8) :
                return "LONDON_ONLY"
            case(hour >= 7) :
                return "LONDON_TOKYO"
            default: // 6 is all that is left
                return "TOKYO ONLY"
        }
    }
}

export default qBotHelper;
