import React from "react";
import {MDBIcon} from "mdbreact";
class AlertSuccess extends React.Component {

    render() {
        return( <div className='alert alert-success mt-2 text-center'>
            <MDBIcon icon='check'/><br/>{this.props.message}
        </div>)
    }
}

export default AlertSuccess;