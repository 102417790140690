import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBChip,
  MDBBadge,
  MDBIcon
} from 'mdbreact';

import './stack.css';

function getNecessaryProfit(highestLevel, startingLot, magic) {
  var profit_factor = 0;
  magic = magic + '';
  if (magic == 999999) {
    //console.log("ARGGGH")
    switch(highestLevel)
    {
     case 2:
          profit_factor = 16;
          break;

       case 3:
          profit_factor = 24;
          break;

       case 4:
          profit_factor = 32;
          break;

       case 5:
          profit_factor = 48;
          break;

       case 6:
          profit_factor = 64;
          break;

       case 7:
          profit_factor = 88;
          break;

       case 8:
          profit_factor = 110;
          break;

       case 9:
          profit_factor = 135;
          break;

       case 10:
          profit_factor = 150;
          break;

       default:
          profit_factor = 180;
    }
  } else if (magic > 900000) {
    //console.log("ARGGGH")
    switch(highestLevel)
    {
     case 2:
        profit_factor = 20;
        break;

     case 3:
        profit_factor = 30;
        break;

     case 4:
        profit_factor = 48;
        break;

     case 5:
        profit_factor = 64;
        break;

     case 6:
        profit_factor = 88;
        break;

     case 7:
        profit_factor = 120;
        break;

     case 8:
        profit_factor = 110;
        break;

     case 9:
        profit_factor = 135;
        break;

     case 10:
        profit_factor = 150;
        break;
    case 13:
      profit_factor = 260;
      break;
    case 14:
      profit_factor = 300;
      break;
    case 15:
    case 16:
      profit_factor = 360;
      break;
    case 17:
      profit_factor = 500;
      break;
    case 18:
    case 19:
    case 20:
      profit_factor = 720;
      break;

     default:
        profit_factor = 180;
    }
  } else if (magic.substring(0,5) == "20007") {
    profit_factor = 7; 
  }else {
    //console.log("BOOOO");
    switch(highestLevel)
      {
       case 2:
          profit_factor = 16;
          break;

       case 3:
          profit_factor = 24;
          break;

       case 4:
          profit_factor = 32;
          break;

       case 5:
          profit_factor = 48;
          break;

       case 6:
          profit_factor = 64;
          break;

       case 7:
          profit_factor = 88;
          break;

       case 8:
          profit_factor = 110;
          break;

       case 9:
          profit_factor = 135;
          break;

       case 10:
          profit_factor = 150;
          break;

       default:
          profit_factor = 180;
      }
    }
  return profit_factor*startingLot;
}
function pipDivider(currency) {
  if (currency.includes("JPY")) {
    return 0.01;
  }
  return 0.0001;
}

function calculateTPFromEntryPrice(levels, maxLevel, currentPrice) {
  var current_gain = 0;
  var pipsLost, volumeLost, totalVolumeLost = 0;

  for(var i=0; i<maxLevel; i++) {
    if(levels[i].volume == 0)
      {
       continue;
      }

    if(levels[i].order_type == 0)
      {
       current_gain = currentPrice - levels[i].open_price;
      }

    if(levels[i].order_type == 1)
      {
       current_gain = levels[i].open_price - currentPrice;
      }

    pipsLost = current_gain/pipDivider(levels[0].currency);
    volumeLost = pipsLost*levels[i].volume;
    totalVolumeLost = totalVolumeLost+volumeLost;

  }

  //console.log("Total Volume Lost: " + totalVolumeLost);
  
  return totalVolumeLost;
}

function calculateVolume(startingLot, tradeVolume, nextLevel, magic) {

  var lotsToUse = startingLot;
  var new_lots = lotsToUse;

  switch (magic) {
    case 10000:
      switch(nextLevel)
       {
        case 2:
           new_lots = lotsToUse;
           break;
       }
       if(nextLevel > 2)
         {
          new_lots = Math.round(tradeVolume*1.25*100)/100;
         }
      break;
    case 20007:
    case 200072:
    case 200073:
    case 200074:
      switch(nextLevel)
       {
        case 2:
           new_lots = lotsToUse;
           break;
       }
       if(nextLevel > 2)
         {
          new_lots = Math.round(tradeVolume*1.5*100)/100;
         }
      break;
    default:
      switch(nextLevel)
       {
        case 2:
        case 3:
           new_lots = lotsToUse;
           break;
        case 4:
        case 5:
        case 6:
           new_lots = Math.round(lotsToUse*1.5*100)/100;
           break;

       }
       if(nextLevel > 6)
         {
          new_lots = Math.round(tradeVolume*1.35*100)/100;
         }

       if(nextLevel > 14)
         {
          new_lots = Math.round(tradeVolume*1.41*100)/100;
         }
  }
  

  return new_lots;
}

function calculateTp(levels, maxLevel, currentPrice) {
  var tp = 0;

  var requiredVolume = getNecessaryProfit(maxLevel,levels[0].volume, levels[0].magic);
  var calculatedVolume = 0;
  //console.log("Required Volume : " + requiredVolume);
  //calculateTPFromEntryPrice(levels, maxLevel, currentPrice);
  var i = 0;
  do
     {
      i++;
      if(levels[0].order_type == 0)
        {
         currentPrice = currentPrice + (0.1*pipDivider(levels[0].currency));
        }
      else
        {
         currentPrice = currentPrice - (0.1*pipDivider(levels[0].currency));
        }

      //currentPrice =  parseFloat(currentPrice)

      calculatedVolume = calculateTPFromEntryPrice(levels, maxLevel, currentPrice);
      //console.log("Tried TP of: " + currentPrice + " : ResultVolume: " + calculatedVolume);
     }
   while(calculatedVolume < requiredVolume);

  return currentPrice;
}

function calcDrawdown(levels, currentLevel, currentPrice) {
  let strPrices = "";
  let drawdown = 0;
  let totalDrawdown = 0;
  for (var i = 0; i < currentLevel; i++) {
    if (levels[i].order_type == 0) {
      drawdown = (levels[i].open_price-currentPrice)/pipDivider(levels[0].currency)*levels[i].volume*7; 
    } else {
      drawdown = (currentPrice-levels[i].open_price)/pipDivider(levels[0].currency)*levels[i].volume*7; 
    }
    
    totalDrawdown += drawdown;
    //strPrices += levels[i].open_price + "/" + currentPrice + "::";
  }
  return totalDrawdown.toFixed(2);
}
class Stack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // No avatars:
      stack: "roger",
    };
  }


  render() {
    const { stack } = this.state;

    //console.log(this.props.stack);

    var levels = [];
    var newObj = {};
    var latestOpenPrice = 0;
    var prevVolume = 0;
    for (var i = 1; i <= 20; i++) {
       prevVolume = calculateVolume(this.props.stack[0].volume, prevVolume, i, this.props.stack[0].magic);
       newObj = {drawdown_pc: 0, drawdown: 0, currency: this.props.stack[0].currency, bank: 0, text: i, disabled: true, className: "d-flex w-100", color: 'blue-grey', open_price: 0, order_type: this.props.stack[0].order_type, volume: prevVolume, take_profit: 0, retrace: 0, magic: this.props.stack[0].magic};
       levels.push(newObj);
       

       //console.log("L" + i + ": " + prevVolume);
    }

    for (var x=0; x<this.props.stack.length; x++) {
      
      levels[x].text = "Engaged @ " + this.props.stack[x].open_price;
      levels[x].disabled = false;
      levels[x].open_price = this.props.stack[x].open_price;
      levels[x].volume = this.props.stack[x].volume;
      if (x < 5) {
        levels[x].color = 'dark-green';
      } else if (x < 7) {
        levels[x].color = 'light-green';
      } else if (x < 10) {
        levels[x].color = 'yellow';
      } else if (x < 13) {  
        levels[x].color = 'amber';
      } else {
        levels[x].color = 'deep-orange';
      }

      latestOpenPrice = this.props.stack[x].open_price;
    }

    var pipGap = 0.00200;
    var tmpMagic = this.props.stack[0].magic + '';

    if (tmpMagic.substring(0,5) == 20007) {
      pipGap = 0.00300;
    }
    var pipMulti = 10000;
    var pipDigits = 5;
    
    if (this.props.stack[0].currency.includes("JPY")) {
      pipGap = 0.200;
      pipMulti = 100;
      pipDigits = 3;
    }

    for (var x=this.props.stack.length; x<20; x++) {
      if (this.props.stack[0].order_type == 0) {
        latestOpenPrice = latestOpenPrice - pipGap;  
      } else {
        latestOpenPrice= latestOpenPrice + pipGap;
      }
      
      latestOpenPrice = Math.round((latestOpenPrice)*pipMulti)/pipMulti;
      levels[x].text = "Engaging @ " + latestOpenPrice.toFixed(pipDigits);
      levels[x].open_price = latestOpenPrice;
    }

    var tp =0;
    var currentPrice = 0;
    var initialPips = 0;
    
    for (var i=1; i<=20; i++) {
      
      currentPrice = levels[i-1].open_price;
      //console.log('Calcualting TP for L' + i + ' with open price of: ' + currentPrice);
      //console.log(levels);

      if (i == 1) {
        tp = this.props.stack[0].take_profit;
        if (this.props.stack[0].order_type == 0) {
          initialPips = Math.round((tp - this.props.stack[0].open_price)/pipDivider(this.props.stack[0].currency));
        } else {
          initialPips = Math.round((this.props.stack[0].open_price - tp)/pipDivider(this.props.stack[0].currency));
        }
        if (this.props.stack.length == 1) {
          levels[i-1].bank = (initialPips*levels[0].volume*7).toFixed(2);
        } else {
          levels[i-1].bank = 0;

        }
        
        
      } else {
        tp = calculateTp(levels, i, currentPrice).toFixed(5);
        //levels[i-1].retrace = tp;
        levels[i-1].bank = (Math.round(getNecessaryProfit(i, levels[0].volume, levels[0].magic)*7*100)/100).toFixed(2);
        levels[i-1].drawdown = calcDrawdown(levels, i-1, currentPrice);
        levels[i-1].drawdown_pc = (levels[i-1].drawdown/17500*100).toFixed(2);
      }
      
      /*
      if (this.props.stack[0].order_type == 0) {
        if (this.props.stack[0].take_profit > tp) {
          tp = this.props.stack[0].take_profit;
        }
      } else {
        if (this.props.stack[0].take_profit < tp) {
          tp = this.props.stack[0].take_profit;
        }
      }
      */

      levels[i-1].take_profit = tp;

      
      
      if (this.props.stack[0].order_type == 0) {
        levels[i-1].retrace = Math.round((tp - currentPrice)/pipDivider(this.props.stack[0].currency));
      } else {
        levels[i-1].retrace = Math.round((currentPrice - tp)/pipDivider(this.props.stack[0].currency));
      }
      
    }

    var totalRetrace = 0;
    for (var i=0; i<this.props.stack.length; i++) {
        totalRetrace = totalRetrace + levels[i].retrace;
    }

    var gapToNext=0;
    if (this.props.stack[0].order_type == 0) {
      gapToNext = Math.round((this.props.stack[0].current_price - levels[this.props.stack.length].open_price)/pipDivider(this.props.stack[0].currency));
    } else {
      gapToNext = Math.round((levels[this.props.stack.length].open_price-this.props.stack[0].current_price)/pipDivider(this.props.stack[0].currency));
    }

    if (gapToNext < 3) {
      //console.log(levels[this.props.stack.length].currency + "; current price: " + this.props.stack[0].current_price + "; next open: " + levels[this.props.stack.length].open_price + "; gap to next: " + gapToNext);
      levels[this.props.stack.length].className = "w-100 blink_me";
    }

    

    //if (tmpMagic.substring(0,5) == 20005) {
      levels[this.props.stack.length-1].p_bank = " - £"+(Math.round(totalRetrace*levels[0].volume*7*100)/100).toFixed(2);
    //}

    return levels.map((item, index) => (
        <div className="d-flex w-100 greenbox">
          <MDBBtn className={item.className} disabled=
            {item.disabled} color={item.color}>L{index+1} {item.text}
            <br/>TP: {item.take_profit}<br />{item.volume} lots
            <br/>{item.retrace} pips
            <br />£{item.bank}{item.p_bank}
            <br />DD: £{item.drawdown} ({item.drawdown_pc}%)</MDBBtn>
        </div>
    ));
  }
}

export default Stack;
