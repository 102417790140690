import React from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
  MDBCollapse,
  MDBContainer,
  MDBNavbarToggler,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBAvatar
} from 'mdbreact';
import './Login.css';

class Login extends React.Component {
  state = {
    collapseID: ''
  };

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }));

  render() {
    return (
      <div className='classic-form-page' id='login'>
        <MDBNavbar
          color='deep-purple darken-4'
          dark
          expand='md'
          fixed='top'
          scrolling
          transparent
        >
          <MDBContainer>
            
            <MDBRow>
              <MDBCol lg="4" md="12" className="mb-6">
                <img src="https://static.wixstatic.com/media/99e530_1dbdc03380324c8988d510e1f18b3351~mv2.png/v1/fill/w_444,h_160,al_c,q_85,usm_0.66_1.00_0.01/MC-logo-2_rev.webp" alt="thumbnail" className="img-thumbnail" />
              </MDBCol>
            </MDBRow>
            
            <MDBNavbarToggler onClick={this.toggleCollapse('navbarCollapse')} />
            <MDBCollapse
              id='navbarCollapse'
              isOpen={this.state.collapseID}
              navbar
            >
        
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>

        <MDBView>
          <MDBMask
            className='d-flex justify-content-center align-items-center'
            overlay='stylish-strong'
          >
            <MDBContainer>
              <MDBRow>
                <MDBCol md='10' lg='6' xl='5' sm='12' className='mt-5 mx-auto'>
                  <MDBCard>
                    <MDBCardBody>
                      <div className='form-header'>
                        <h3>
                          <MDBIcon
                            icon='user'
                            className='mt-2 mb-2 text-white'
                          />{' '}
                          Log in:
                        </h3>
                      </div>
                      <MDBInput
                        type='text'
                        label='Your name'
                        icon='user'
                        iconClass='white-text'
                      />
                      <MDBInput
                        type='email'
                        label='Your email'
                        icon='envelope'
                        iconClass='white-text'
                      />
                      <MDBInput
                        type='password'
                        label='Your password'
                        icon='lock'
                        iconClass='white-text'
                      />
                      <div className='text-center mt-3 black-text'>
                        <MDBBtn size='lg'>
                          Log In
                        </MDBBtn>
                        <hr />
                      </div>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    );
  }
}

export default Login;
