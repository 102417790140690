import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';

import RoutesWithNavigation from './components/RoutesWithNavigation';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';


import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Pricing from './components/pages/Pricing';
import Lock from './components/pages/Lock';
import About from './components/pages/About';
import SinglePost from './components/pages/SinglePost';
import PostListing from './components/pages/PostListing';
import Landing from './components/pages/Landing';

import { AmplifyAuthenticator, withAuthenticator, AmplifyConfirmSignIn, AmplifyConfirmSignUp, AmplifySignUp, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';


Amplify.configure(awsconfig);
const App = () => {
  return (
    <AmplifyAuthenticator usernameAlias="email" amplifyConfig={awsconfig}>
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: "email",
            label: "Email",
            placeholder: "Enter your email address...",
            required: true,
          },
          {
            type: "password",
            label: "Password",
            placeholder: "Pick a password",
            required: true,
          },
          {
            type: "phone_number",
            label: "Phone Number",
            placeholder: "",
            required: false,      
          },
        ]} 
      />
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
      
      <Switch>
        <Route path='/login' exact component={Login} />
        <Route path='/pages/register' exact component={Register} />
        <Route path='/pages/pricing' exact component={Pricing} />
        <Route path='/pages/lock' exact component={Lock} />
        <Route path='/pages/about' exact component={About} />
        <Route path='/pages/post' exact component={SinglePost} />
        <Route path='/pages/posts' exact component={PostListing} />
        <Route path='/pages/landing' exact component={Landing} />
        <RoutesWithNavigation />
      </Switch>
    </AmplifyAuthenticator>
  );
};

export default App;
