import React from 'react';
import { Pie } from "react-chartjs-2";

class PieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartHeaders: null,
            dataPie: {
                labels: this.props.chartHeaders,
                datasets: [
                    {
                        data: this.props.data,
                        backgroundColor: this.props.bgColours,
                        hoverBackgroundColor: this.props.hoverColours
                    }
                ]
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.chartHeaders !== prevProps.chartHeaders) {
            this.setState({
                dataPie: {
                    labels: this.props.chartHeaders,
                    datasets: [
                        {
                            data: this.props.data,
                            backgroundColor: this.props.bgColours,
                            hoverBackgroundColor: this.props.hoverColours
                        }
                    ]
                }
            });
        }
    }

    render() {
        return (<div className='col-10 offset-1'>
                    <h3 className="mt-5 text-center">{this.props.title}</h3>
                    <Pie className='col-12' data={this.state.dataPie} options={{ responsive: true }} />
            </div>
        );
    }
}
export default PieChart;
