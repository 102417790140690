import React from 'react';
import {Auth} from "aws-amplify";
import decode from "jwt-decode";
import qbotHelper from "../../helpers/qbotHelper";

import {MDBBtn, MDBDataTableV5, MDBIcon, MDBView, MDBCard, MDBContainer, MDBCardBody} from "mdbreact";
import {CSVLink} from "react-csv";

let loaded = false;
const QbotBreakdown = () => {

    const [qbotBreakdownTable, setQbotBreakdownTable] = React.useState({
        columns: [
            {
                label: 'Symbol',
                field: 'symbol',
                width: 250,
            },
            {
                label: 'Profit',
                field: 'profit',
                width: 250,
            },
            {
                label: 'Magic',
                field: 'magic',
                width: 250,
            },
            {
                label: 'Max Level',
                field: 'max_level',
                width: 250,
            },
            {
                label: 'Buy / Sell',
                field: 'buy_sell',
                width: 250,
            },
            {
                label: 'Date Closed',
                field: 'close_time',
                width: 250,
            },
            {
                label: 'TimeZone',
                field: 'timezone',
                width: 250,
            }
        ],

        rows: [

        ],
    });

    let tmpQbotTable = JSON.parse(JSON.stringify(qbotBreakdownTable))


    if (false === loaded) {
        loaded = true;
        loadData()
    }


    function loadData() {
        const tokens = Auth.currentSession().then(function (e) {
            let u = e.getIdToken().payload['cognito:username'];
            let jwtToken = e.getIdToken().jwtToken;
            let cognitoProfile = decode(jwtToken);
            let url = 'https://botdashapi.fxsgnl.io/trade_history?1=1&group=stack_hash';

            fetch(url, {
                headers: {
                    "x-api-key": jwtToken
                },
            }).then((response) => response.json())
                .then((findresponse) => {
                    tmpQbotTable.rows = findresponse;
                    for (let i = 0; i < tmpQbotTable.rows.length; i++) {
                        let timeBreakdown = tmpQbotTable.rows[i].close_time.split(' ');
                        tmpQbotTable.rows[i].timezone = qbotHelper.getTimeZone(timeBreakdown[1][0] + timeBreakdown[1][1]);
                        tmpQbotTable.rows[i].magic = qbotHelper.getQBotName(tmpQbotTable.rows[i].magic);
                        tmpQbotTable.rows[i].profit = Number(tmpQbotTable.rows[i].profit).toFixed(2);
                    }

                    setQbotBreakdownTable(tmpQbotTable);
                })
        });
    }

    let breakdownCsvData = [];
    let today = new Date().toLocaleString();
    today = today.replace(',', '_');
    let breakdownCsvName = 'qbot_breakdown_'+today;


    for (let i = 0; i < tmpQbotTable.rows.length; i++) {
        breakdownCsvData[i] = {
            'currency': tmpQbotTable.rows[i].symbol,
            'profit': tmpQbotTable.rows[i].profit,
            'magic': tmpQbotTable.rows[i].magic,
            'max level': tmpQbotTable.rows[i].max_level,
            'buy / sell': tmpQbotTable.rows[i].buy_sell,
            'close_time': tmpQbotTable.rows[i].close_time,
            'timezone': qbotBreakdownTable.rows[i].timezone
        }
    }

    return (
        <MDBContainer fluid className="p-2">
            <MDBCard narrow className='pb-3'>
                <MDBView
                    cascade
                    className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
                >
                    <div className='text-left'>
                        <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                            <MDBIcon icon='th-large' className='mt-0' />
                        </MDBBtn>
                        <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                            <MDBIcon icon='columns' className='mt-0' />
                        </MDBBtn>
                    </div>
                    <a href='#' className='white-text mx-3'>
                        Qbot Breakdown Report
                    </a>
                    <div className='text-right'>
                        <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                            <MDBIcon id="refresh" icon='info-circle' className='mt-0' />
                        </MDBBtn>
                    </div>
                </MDBView>
                <MDBCardBody>
                    <CSVLink className='btn btn-primary' data={breakdownCsvData} filename={breakdownCsvName} >
                        <MDBIcon far icon="file-excel" className="pr-1" />
                        Export to CSV
                    </CSVLink>
                    <MDBDataTableV5
                        scrollX
                        striped
                        hover
                        entriesOptions={[5,15,25]}
                        entries={5}
                        pagesAmount={4}
                        data={qbotBreakdownTable}
                        multipleCheckboxes
                        proCheckboxes
                        filledCheckboxes
                        proSelect
                        paging={false}
                        searchTop
                        searchBottom={false}
                        className='text-center'
                    />
                </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    )
}


export default QbotBreakdown;