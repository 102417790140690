import React from 'react';
import {MDBRow, MDBCol, MDBCard, MDBCardBody, MDBInput} from 'mdbreact';


class FilterByMagicAndSymbolForm extends React.Component {
    constructor(props) {
        super(props);
        // these values will have been set based on the query string params from the parent component
        this.state = {
            selected_magic: (null === this.props.selected_magic) ? 'ALL' : this.props.selected_magic,
            selected_symbol: (null === this.props.selected_symbol) ? 'All' : this.props.selected_symbol,

        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMagicSelect = this.handleMagicSelect.bind(this);
        this.handleSymbolSelect = this.handleSymbolSelect.bind(this);
        this.onChangeFunction = this.props.onChangeFunction.bind(this)
    }

    handleSymbolSelect(event) {
        this.setState({
            selected_symbol : event.target.value
        }, () => {
            this.handleSubmit()
        });
    }


    handleMagicSelect(event) {
        console.log(event.target.value);
        this.setState({
            selected_magic: event.target.value
        }, () => {
            this.handleSubmit()
        });
    }

    handleSubmit() {
        const magicFilter = this.state.selected_magic;
        const symbolFilter = this.state.selected_symbol;
        this.onChangeFunction(magicFilter, symbolFilter);
    }

    render () {
        return (
            <section>
                <form onSubmit={this.handleSubmit}>
                    <MDBCard className='mb-5'>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol md='12' lg='4' className='mb-4'>
                                    <label>
                                        Select a Magic
                                    </label>
                                    <select onChange={this.handleMagicSelect}
                                            className="form-control col-12" id="magicSelect"
                                            value={this.state.selected_magic}
                                    >
                                        {this.props.magics.map(magic => {
                                            return <option value={magic.id} key={magic.id}>
                                                {magic.name}
                                            </option>
                                        })}
                                    </select>
                                </MDBCol>
                                <MDBCol md='12' lg='4' className='mb-4'>
                                    <label>
                                        Select a Symbol
                                    </label>
                                    <select
                                        onChange={this.handleSymbolSelect}
                                        className="form-control col-12 "
                                        defaultValue={this.state.selected_symbol}
                                        id="magicSelect"
                                    >
                                        {this.props.symbols.map(symbol => {
                                            return <option value={symbol.name} key={symbol.name}>
                                                {symbol.name}
                                            </option>
                                        })}
                                    </select>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </form>
            </section>
        )
    }
}
export default FilterByMagicAndSymbolForm;