import React, {useEffect} from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBIcon,
  MDBBadge,
  MDBProgress,
  MDBBtn,
  MDBTable,
  MDBView,
  MDBDatePicker
} from 'mdbreact';
import Amplify, { Auth } from 'aws-amplify';
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import decode from 'jwt-decode';
import $ from 'jquery';
import TradingView from "./tradingView";

const lineChartData = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Profit (%)',
      backgroundColor: 'rgba(255,255,255,0.4)',
      borderColor: '#fff',
      pointBackgroundColor: 'transparent',
      borderWidth: 2,
      pointBorderColor: '#fff',
      pointBorderWidth: 1,
      data: [4.51, 5.21, 4.82, 4.21, 4.81, 3.41, 5.6]
    }
  ]
};

const lineChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  legend: {
    labels: {
      fontColor: 'white',
      fontSize: 18
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,
          color: 'rgba(255, 255, 255, 0.2)'
        },
        ticks: {
          fontColor: '#fff'
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: 'rgba(255, 255, 255, 0.2)'
        },
        ticks: {
          fontColor: '#fff'
        }
      }
    ]
  }
};





const DV6 = () => {

  function loadProfit() {
    
    const tokens = Auth.currentSession().then(function(e){
      let u = e.getIdToken().payload['cognito:username'];
      let jwtToken = e.getIdToken().jwtToken;
      //console.log(jwtToken);
      let cognitoProfile = decode(jwtToken);
      fetch('https://botdashapi.fxsgnl.io/profit', {
        headers: {
          "x-api-key": jwtToken
        },
      })
      .then((response) => response.json())
      .then((findresponse)=>{
        
        console.log(findresponse[0].mtd_profit_percent);
        $(() => $('#mtd').html(findresponse[0].mtd_profit_percent + "%"));
        $(() => $('#ytd').html(findresponse[0].ytd_profit_percent + "%"));
        $(() => $('#mtd_pound').html("£" + findresponse[0].mtd_profit + ""));
        
      })
    });
  }

  const [authState, setAuthState] = React.useState();
  const [state, setState] = React.useState();
  React.useEffect(() => {
    return onAuthUIStateChange(newAuthState => {
      if(newAuthState === 'signedin'){
        loadProfit();
      }
      setAuthState(newAuthState)
    });
  }, []);

  return (
    <MDBContainer fluid id='v6' className='mb-5'>
      <section className='mb-4'>
        <MDBRow>
          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard color="black">
              <MDBRow className='mt-3'>
                <MDBCol md='5' size='5' className='text-left pl-4'>
                  <MDBBtn
                    tag='a'
                    floating
                    size='lg'
                    color='primary'
                    className='ml-4'
                    style={{ padding: 0 }}
                  >
                    <MDBIcon icon='eye' size='2x' />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md='7' col='7' className='text-right pr-5'>
                  <h5 id="mtd" className='ml-4 mt-4 mb-2 font-weight-bold white-text' >loading</h5>
                  <p className='font-small grey-text'>MTD</p>
                </MDBCol>
              </MDBRow>
              <MDBRow className='my-3'>
                <MDBCol md='7' col='7' className='text-left pl-4'>
                  <p className='font-small white-text font-up ml-4 font-weight-bold'>
                    This Month %
                  </p>
                </MDBCol>
                <MDBCol md='5' col='5' className='text-right pr-5'>
                  <p className='font-small grey-text'></p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>

          

          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard color="black">
              <MDBRow className='mt-3'>
                <MDBCol md='5' col='5' className='text-left pl-4'>
                  <MDBBtn
                    tag='a'
                    floating
                    size='lg'
                    color='info'
                    className='ml-4'
                    style={{ padding: 0 }}
                  >
                    <MDBIcon icon='dollar-sign' size='2x' />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md='7' col='7' className='text-right pr-5'>
                  <h5 id="mtd_pound" className='ml-4 mt-4 mb-2 font-weight-bold white-text'>loading</h5>
                  <p className='font-small grey-text'>MTD</p>
                </MDBCol>
              </MDBRow>
              <MDBRow className='my-3'>
                <MDBCol md='7' col='7' className='text-left pl-4'>
                  <p className='font-small white-text font-up ml-4 font-weight-bold'>
                    This Month &pound;
                  </p>
                </MDBCol>
                <MDBCol md='5' col='5' className='text-right pr-5'>
                  <p className='font-small grey-text'></p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard color="black">
              <MDBRow className='mt-3'>
                <MDBCol md='5' col='5' className='text-left pl-4'>
                  <MDBBtn
                    tag='a'
                    floating
                    size='lg'
                    color='warning'
                    className='ml-4'
                    style={{ padding: 0 }}
                  >
                    <MDBIcon icon='user' size='2x' />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md='7' col='7' className='text-right pr-5'>
                  <h5 id="ytd" className='ml-4 mt-4 mb-2 font-weight-bold white-text'>loading</h5>
                  <p className='font-small grey-text'>YTD</p>
                </MDBCol>
              </MDBRow>
              <MDBRow className='my-3'>
                <MDBCol md='7' col='7' className='text-left pl-4'>
                  <p className='font-small white-text font-up ml-4 font-weight-bold'>
                    This Year %
                  </p>
                </MDBCol>
                <MDBCol md='5' col='5' className='text-right pr-5'>
                  <p className='font-small grey-text'></p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4 mb-r'>
            <MDBCard color="black">
              <MDBRow className='mt-3'>
                <MDBCol md='5' col='5' className='text-left pl-4'>
                  <MDBBtn
                    tag='a'
                    floating
                    size='lg'
                    color='danger'
                    className='ml-4'
                    style={{ padding: 0 }}
                  >
                    <MDBIcon icon='database' size='2x' />
                  </MDBBtn>
                </MDBCol>
                <MDBCol md='7' col='7' className='text-right pr-5'>
                  <h5 id="ytd_pound" className='ml-4 mt-4 mb-2 font-weight-bold white-text'>&pound;4.6m</h5>
                  <p className='font-small grey-text'>AUM</p>
                </MDBCol>
              </MDBRow>
              <MDBRow className='my-3'>
                <MDBCol md='7' col='7' className='text-left pl-4'>
                  <p className='font-small white-text font-up ml-4 font-weight-bold'>
                    AUM &pound;
                  </p>
                </MDBCol>
                <MDBCol md='5' col='5' className='text-right pr-5'>
                  <p className='font-small grey-text'></p>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
      <section className='mb-12'>
        <MDBCard color="black" cascade narrow>
          <MDBRow>
            <MDBCol md='12' className='mr-0'>
              <MDBCardBody id="calendar" style={{height: 500+'px'}} cascade className='pb-3'>
                <TradingView />
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBCard>
      </section>
    </MDBContainer>

  );
};

export default DV6;
