import React from 'react';
import {
  MDBCol,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import QbotHelper from "../../helpers/qbotHelper";
import './spratset.css';


class SignalPip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // No avatars:
    };
  }

  render() {
    const ribbons: JSX.Element[] = [];
    let signals = []
    if (!this.props.signals) {
      signals = [];
    } else {
      signals = this.props.signals;
    }

    ribbons.push(<span className="charity">
      <span style={{fontWeight: 'bold', fontSize: 13 + "px", position: "absolute", textAlign: "center", width: 22 + "px", paddingTop:1 +"px"}}>0</span>
      <MDBIcon icon='fas fa-circle' className="fa-lg" style={{color: '#FFDD3C'}}/>
  </span>);
    for(let i=0; i < signals.length; i++) {
      if (signals[i].active == 1) {
        ribbons.push(<span className="charity">
      <span style={{fontWeight: 'bold', fontSize: 13 + "px", position: "absolute", textAlign: "center", width: 22 + "px", paddingTop:1 +"px"}}>{signals[i].signal}</span>
      <MDBIcon icon='fas fa-circle' className="fa-lg" style={{color: '#FFDD3C'}}/>
  </span>);
      }
    }
    return (
      <div>
      {ribbons}
      </div>
    );
  }
}

class StackSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // No avatars:
    };
  }
  
  render() {
    let signals = [];
    let tmpSentiment = [];
    for (let y=0;y<29;y++) {
      signals[y] = {tradesignals: 2, sellsignals:2, buysignals: 1}
      tmpSentiment[y] = {long_percent: 50, short_percent: 50};
    }
    
    if (this.props.signals.rows.length > 0) {
      signals = this.props.signals.rows;
    }

    if (this.props.sentiment.rows.length > 0) {
      for (let x=0;x<this.props.sentiment.rows.length;x++) {
        tmpSentiment[QbotHelper.getCurrencyId(this.props.sentiment.rows[x].symbol)].long_percent = this.props.sentiment.rows[x].latest_long_percentage;
        tmpSentiment[QbotHelper.getCurrencyId(this.props.sentiment.rows[x].symbol)].short_percent = this.props.sentiment.rows[x].latest_short_percentage;

        tmpSentiment[QbotHelper.getCurrencyId(this.props.sentiment.rows[x].symbol)].four_hour_long_percent = this.props.sentiment.rows[x].four_hour_long_percentage;
        tmpSentiment[QbotHelper.getCurrencyId(this.props.sentiment.rows[x].symbol)].four_hour_short_percent = this.props.sentiment.rows[x].four_hour_short_percentage;

        tmpSentiment[QbotHelper.getCurrencyId(this.props.sentiment.rows[x].symbol)].one_day_long_percent = this.props.sentiment.rows[x].one_day_long_percentage;
        tmpSentiment[QbotHelper.getCurrencyId(this.props.sentiment.rows[x].symbol)].one_day_short_percent = this.props.sentiment.rows[x].one_day_short_percentage;
      }
    }
     
    let prices = [];
    if (this.props.prices) {
      prices = this.props.prices;
    } else {
      prices[0] = 5;
    }

    for (let i=0;i<this.props.sprats.length-1;i++) {

      if (this.props.ordertype==0) {
        this.props.sprats[i].tradesignals = signals[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].buysignals;
        this.props.sprats[i].signals = signals[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].buySignalsArray;
        this.props.sprats[i].current_level = this.props.sprats[i].sprat_zero_buy_level;
        this.props.sprats[i].current_tp = this.props.sprats[i].sprat_zero_buy_tp;
        this.props.sprats[i].secondary_level = this.props.sprats[i].sprat_two_buy_level;
        this.props.sprats[i].secondary_tp = this.props.sprats[i].sprat_two_buy_tp;
        this.props.sprats[i].tertiary_level = this.props.sprats[i].sprat_v7a_buy_level;
        this.props.sprats[i].tertiary_tp = this.props.sprats[i].sprat_v7a_buy_tp;
        
        
      } else {
        this.props.sprats[i].tradesignals = signals[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].sellsignals;
        this.props.sprats[i].signals = signals[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].sellSignalsArray;
        this.props.sprats[i].current_level = this.props.sprats[i].sprat_zero_sell_level;
        this.props.sprats[i].current_tp = this.props.sprats[i].sprat_zero_sell_tp;
        this.props.sprats[i].secondary_level = this.props.sprats[i].sprat_two_sell_level;
        this.props.sprats[i].secondary_tp = this.props.sprats[i].sprat_two_sell_tp;
        this.props.sprats[i].tertiary_level = this.props.sprats[i].sprat_v7a_sell_level;
        this.props.sprats[i].tertiary_tp = this.props.sprats[i].sprat_v7a_sell_tp;

      }

      this.props.sprats[i].short_percent = tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].short_percent;
      this.props.sprats[i].long_percent = tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].long_percent;
      this.props.sprats[i].four_hour_short_percent = tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].four_hour_short_percent;
      this.props.sprats[i].four_hour_long_percent = tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].four_hour_long_percent;
      this.props.sprats[i].one_day_short_percent = tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].one_day_short_percent;
      this.props.sprats[i].one_day_long_percent = tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].one_day_long_percent;
      
      this.props.sprats[i].short_percent_style = { width: tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].short_percent + '%'};
      this.props.sprats[i].long_percent_style = { width: tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].long_percent + '%'}
      this.props.sprats[i].four_hour_short_percent_style = { width: tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].four_hour_short_percent + '%'};
      this.props.sprats[i].four_hour_long_percent_style = { width: tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].four_hour_long_percent + '%'}
      this.props.sprats[i].one_day_short_percent_style = { width: tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].one_day_short_percent + '%'};
      this.props.sprats[i].one_day_long_percent_style = { width: tmpSentiment[QbotHelper.getCurrencyId(this.props.sprats[i].currency)].one_day_long_percent + '%'}
      if (this.props.sprats[i].secondary_level >= 9) {
        this.props.sprats[i].color = {backgroundColor: 'green'}; 
      } else if (this.props.sprats[i].secondary_level >= 7) { 
        this.props.sprats[i].color = {backgroundColor: '#ffaa42'}; 
      } else {
        this.props.sprats[i].color = {backgroundColor: '#454545'}; 
      }
    }

    return this.props.sprats.map((item, index) => (
      <div className="roger tradealert col-md-12" style={item.color} key={index}>

          <MDBRow className="roger col-md-12" style={{ marginBottom: 15 + "px"}}>
            <MDBCol className='text-left col-md-6'>
              <h5 id="mtd_pound" className='font-weight-bold white-text'>{item.currency}</h5>
              <p className="white-text font-small">
                <MDBIcon icon="chart-line" /> {item.days_since_spike} Days Since Spike <br/>
                <MDBIcon icon="globe-europe" /> {item.days_since_global_spike} Days Since Global Spike
              </p>
              <SignalPip number={item.tradesignals} signals={item.signals}/>
              <p className='font-small white-text'>Measure:<br />
                <a className='font-small white-text' href={'stacks?account=0&symbol='+item.currency}>{item.current_level}</a>
                / <a className='font-small white-text' href={'stacks?account=2&symbol='+item.currency}> {item.secondary_level}</a>
                / <a className='font-small white-text' href={'stacks?account=6&symbol='+item.currency}>{item.tertiary_level}</a>
              </p>
            </MDBCol>
            <MDBCol className='text-right col-md-6'>
              <h5 id="mtd_pound" className='font-weight-bold white-text'>{prices[QbotHelper.getCurrencyId(item.currency)]}</h5>
              <p className='font-small white-text text-right'>
                TP1: {item.current_tp}<br />TP2: {item.secondary_tp}<br />TP3: {item.tertiary_tp}</p>
            </MDBCol>
            <MDBCol size='12'>
              <div className="progress" style={{marginBottom: 5 + "px", fontWeight: 'bold', height: 20 + "px"}}>
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={item.long_percent_style}
                  aria-valuenow={item.long_percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                {item.long_percent}% Long 1H
                </div>
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={item.short_percent_style}
                  aria-valuenow={item.short_percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                {item.short_percent}% Short 1H
                </div>
                
              </div>
             
            </MDBCol>
            <MDBCol size='12'>
              <div className="progress" style={{marginBottom: 5 + "px", fontWeight: 'bold', height: 20 + "px"}}>
                
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={item.four_hour_long_percent_style}
                  aria-valuenow={item.four_hour_long_percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                {item.four_hour_long_percent}% Long 4H
                </div>
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={item.four_hour_short_percent_style}
                  aria-valuenow={item.four_hour_short_percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                {item.four_hour_short_percent}% Short 4H
                </div>
                
              </div>
            </MDBCol>
            <MDBCol size='12'>
              <div className="progress" style={{marginBottom: 5 + "px", fontWeight: 'bold', height: 20 + "px"}}>
                
                <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style={item.one_day_long_percent_style}
                  aria-valuenow={item.one_day_long_percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                {item.one_day_long_percent}% Long 1D
                </div>
                <div
                  className="progress-bar bg-warning"
                  role="progressbar"
                  style={item.one_day_short_percent_style}
                  aria-valuenow={item.one_day_short_percent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                {item.one_day_short_percent}% Short 1D
                </div>
              </div>
            </MDBCol>
          </MDBRow>
      </div>
    ));
  }
}

export default StackSet;
