import React from 'react';
import {
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBIcon,
    MDBBtn,
    MDBView,
    MDBDataTableV5
} from 'mdbreact';
import { CSVLink } from "react-csv";
import FilterByForm from '../components/filterByForm';
import { Auth } from 'aws-amplify';
import QbotHelper from "../../helpers/qbotHelper";
let loaded = false;

const Journal = () => {

    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: 'Message',
                field: 'entrynote',
                width: 1000,

            },
            {
                label: 'Category',
                field: 'category',
            },
            {
                label: 'Sprat',
                field: 'sprat'
            },
            {
                label: 'Symbol',
                field: 'symbol',
            },
            {
                label: 'Timestamp',
                field: 'timestamp',
            },
        ],

        rows: [],
    });

    let tmpDatatable = JSON.parse(JSON.stringify(datatable))
    if (loaded === false){
        loaded = true;
        loadJournalEntries();
    }

    function loadJournalEntries() {
        const urlParams = new URLSearchParams(window.location.search);
        let magicId     = urlParams.get('magic');
        let symbol      = urlParams.get('symbol');
        let categories  = urlParams.get('exclude_categories');
        let url         = "https://botdashapi.fxsgnl.io/brain?1=1";

        if (null !== magicId) {
            url += "&magic="+magicId;
        }
        if (null !== symbol) {
            url += "&symbol="+symbol;
        }
        if (null !== categories) {
            url += "&exclude_categories="+categories;
        }

        const tokens = Auth.currentSession().then(function (e) {
            let u = e.getIdToken().payload['cognito:username'];
            let jwtToken = e.getIdToken().jwtToken;
            fetch(url, {
                headers: {
                    "x-api-key": jwtToken
                },
            })
                .then((response) => response.json())
                .then((findresponse) => {
                    tmpDatatable.rows = findresponse;
                    if (!tmpDatatable.rows) {
                        tmpDatatable.rows = [];
                    }
                    let qbotName = '';
                    for (let i = 0; i < tmpDatatable.rows.length; i++) {
                        qbotName = QbotHelper.getQBotName(tmpDatatable.rows[i].sprat);
                        tmpDatatable.rows[i].sprat = qbotName;
                    }
                    setDatatable(tmpDatatable);
                });
        });
    }
    const urlParams = new URLSearchParams(window.location.search);
    let magicId     = urlParams.get('magic');
    let symbol      = urlParams.get('symbol');
    let categories  = urlParams.get('exclude_categories');
    let loadJournalEntriesFunction = loadJournalEntries;
    let csvData = [];
    let today = new Date().toLocaleString();
    today = today.replace(',', '_');
    today = today.replace(' ', '_');
    let csvFilename = 'journal_download_'+today;
    for (let i = 0; i < tmpDatatable.rows.length; i++) {
        csvData[i] = tmpDatatable.rows[i];
    }


    return (
        <div>
            <MDBContainer fluid className="p-2">
                <MDBCard narrow className='pb-3'>
                    <MDBView
                        cascade
                        className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
                    >
                        <div className='text-left'>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='th-large' className='mt-0' />
                            </MDBBtn>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='columns' className='mt-0' />
                            </MDBBtn>
                        </div>
                        <a href='#!' className='white-text mx-3'>
                            Journal
                        </a>
                        <div className='text-right'>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon id="refresh" icon='info-circle' className='mt-0' />
                            </MDBBtn>
                        </div>
                    </MDBView>
                    <MDBCardBody>
                        <FilterByForm
                            categories={QbotHelper.getCategories()}
                            magics={QbotHelper.getQbotArray()}
                            symbols={QbotHelper.getSymbolArray()}
                            excluded_cats={categories}
                            selected_symbol={symbol}
                            selected_magic={magicId}
                            loadJournalEntriesFunction={loadJournalEntriesFunction}
                        />
                        <CSVLink class='btn btn-primary' data={csvData} filename={csvFilename} >
                            <MDBIcon far icon="file-excel" className="pr-1" />
                            Export to CSV
                        </CSVLink>
                        <MDBDataTableV5
                            order={['timestamp', 'desc' ]}
                            scrollX
                            striped
                            hover
                            entriesOptions={[5,15,25]}
                            entries={5}
                            pagesAmount={4}
                            data={datatable}
                            multipleCheckboxes
                            proCheckboxes
                            filledCheckboxes
                            proSelect
                            paging={false}
                            searchTop
                            searchBottom={false}
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        </div>
    );
};

export default Journal;
