import React from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBContainer, MDBIcon, MDBView} from "mdbreact";
import SettingsForm from "./SettingsForm";
import QbotHelper from "../../helpers/qbotHelper";
import {Auth} from "aws-amplify";
import decode from "jwt-decode";

let loaded = false;
const SettingsPage = () => {

    const [formData, setFormData] = React.useState({global: {}, currencies: {}});
    const [currentData, setCurrentData] = React.useState({});
    const [oldData, setOldData] = React.useState({});

    if (false === loaded) {
        loadData();
        loaded = true;
    }

    function loadData() {
        const tokens = Auth.currentSession().then(function (e) {
            let u = e.getIdToken().payload['cognito:username'];
            let jwtToken = e.getIdToken().jwtToken;
            let cognitoProfile = decode(jwtToken);
            let url = 'https://botdashapi.fxsgnl.io/settings?1=1';

            fetch(url, {
                headers: {
                    "x-api-key": jwtToken
                },
            }).then((response) => response.json())
                .then((findresponse) => {
                    let formattedFormData = {};
                    let currentValues = {};
                    formattedFormData['global'] = {};
                    formattedFormData['currencies'] = {};
                    findresponse.map(cat => {
                        let friendlyName = cat.setting.replace('_', ' ');
                        if (cat.global) {
                            formattedFormData['global'][cat.setting] = {
                                name:cat.setting,
                                value:cat.value,
                                friendly_name: friendlyName
                            }

                        } else {
                            formattedFormData['currencies'][cat.setting] = {
                                name:cat.setting,
                                value:cat.value,
                                friendly_name: friendlyName
                            }

                        }

                        currentValues[cat.setting] = cat.value === 1;
                        oldData[cat.setting] = cat.value === 1;

                    });
                    setFormData(formattedFormData);
                    setCurrentData(currentValues);
                    setOldData(oldData);

                })
        });
    }
    return (
        <div>
            <MDBContainer fluid className="p-2">
                <MDBCard narrow className='pb-3'>
                    <MDBView
                        cascade
                        className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
                    >
                        <div className='text-left'>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='th-large' className='mt-0' />
                            </MDBBtn>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='columns' className='mt-0' />
                            </MDBBtn>
                        </div>
                        <a href='#' className='white-text mx-3'>
                            Settings
                        </a>
                        <div className='text-right'>

                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon id="refresh" icon='info-circle' className='mt-0' />
                            </MDBBtn>
                        </div>
                    </MDBView>
                    <MDBCardBody className='mb-10'>
                        <SettingsForm
                            currencies={QbotHelper.getSymbolArray(true)}
                            formData={formData}
                            currentData={currentData}
                            oldData={oldData}
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        </div>
    );
}


export default SettingsPage;