import React from 'react';
import {
  MDBSideNavLink,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon
} from 'mdbreact';

class SideNavigation extends React.Component {
  // render MDBSideNav Link
  rSNL(to, text) {
    return (
      <MDBSideNavLink to={to} onClick={this.props.onLinkClick}>
        {text}
      </MDBSideNavLink>
    );
  }

  render() {
    const { onLinkClick } = this.props;
    return (
      <div className='black-skin'>
        <MDBSideNav
          logo='/alogo.webp'
          bg='https://static.wixstatic.com/media/99e530_1553dd5127184b869e91ae0992238553~mv2.jpg/v1/fill/w_245,h_142,al_c,q_80,usm_0.66_1.00_0.01,blur_2/99e530_1553dd5127184b869e91ae0992238553~mv2.jpg'
          mask='strong'
          fixed
          breakWidth={this.props.breakWidth}
          triggerOpening={this.props.triggerOpening}
          style={{ height: '200px', transition: 'padding-left .3s' }}
        >
          
          <MDBSideNavNav>
            <MDBSideNavCat
              name='Home'
              id='dashboard-cat'
              icon='tachometer-alt'
            >
              {this.rSNL('/dashboard', 'Dashboard')}
            </MDBSideNavCat>
            <MDBSideNavCat name='Accounts' id='profile-cat' icon='user'>
              {this.rSNL('/accounts/journal', 'Journal')}
              {this.rSNL('/accounts/signals', 'Signals')}
              {this.rSNL('/accounts/stacks', 'Stacks')}
            </MDBSideNavCat>
            <MDBSideNavCat name='Reports' id='profile-cat' icon='chart-pie'>
              {this.rSNL('/reports/trade-history', 'Trade History')}
              {this.rSNL('/reports/qbot-breakdown', 'Qbot Breakdown')}
              {this.rSNL('/reports/closed-stacks-stats', 'Closed Stacks Stats')}
            </MDBSideNavCat>
            <MDBSideNavLink to="/settings" topLevel>
              <MDBIcon icon="cog" className="mr-2" />Settings
            </MDBSideNavLink>
          </MDBSideNavNav>
        </MDBSideNav>
      </div>
    );
  }
}

export default SideNavigation;
