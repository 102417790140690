import React from 'react';
import {MDBRow, MDBCol, MDBCard, MDBContainer, MDBIcon, MDBInput, MDBBtn} from 'mdbreact';
import {Auth} from "aws-amplify";
import decode from "jwt-decode";
import AlertSuccess from "../alerts/alert-success";

class SettingsForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.state = {
            showSuccessMessage: false
        }
    }

    async makeCall(postArray) {
        const tokens = Auth.currentSession().then(function (e) {
            let u = e.getIdToken().payload['cognito:username'];
            let jwtToken = e.getIdToken().jwtToken;
            let cognitoProfile = decode(jwtToken);
            let that = this;
            let url = 'https://botdashapi.fxsgnl.io/settings?1=1';
            fetch(url, {
                method: 'PUT',
                body: JSON.stringify(postArray),
                credentials: 'same-origin',
                headers: {
                    "x-api-key": jwtToken,
                    'Content-Type': 'application/json'
                },

            }).then(response => response.json())
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        let postArray = [];
        // compare old and new data and build an array from the diff
        let anythingChanged = false;
        let i = 0;
        Object.keys(this.state.currentData).map((key) => {
            let name = key;
            let currentValue = this.state.currentData[name];
            let oldValue = this.state.oldData[name];

            if (currentValue !== oldValue) {
                let updateData = {
                    "setting": name.toString(),
                    'value': currentValue
                };
                postArray[i] = updateData;
                i++;
                anythingChanged = true
            }

        });

        if (anythingChanged) {
            let newOldState = {...this.state.currentData};
            await this.makeCall(postArray).then(response => {
                this.setState({oldData: newOldState, showSuccessMessage: true});
            });
        }
    }

    handleCheckbox(event) {
        let settingName = event.target.name;
        let currentState = this.state;
        currentState.currentData[settingName] = event.target.checked;
        currentState.showSuccessMessage = false;
        this.setState(currentState)

    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props !== prevProps) {
            this.setState({
                currentData: this.props.currentData,
                oldData: this.props.oldData,
                showSuccessMessage: this.state.showSuccessMessage

            });
        }
    }

    render() {
        return(
            <section>
                <form onSubmit={this.handleSubmit}>
                    <MDBCard className='mb-5'>
                        <MDBContainer>
                            { this.state.showSuccessMessage ? <AlertSuccess message='The operation completed successfully'/> : null }
                            <MDBRow>
                                <MDBCol className='mt-5 col-12'>
                                    <MDBRow className='mt-5 col-3 mb-5'>
                                    <h3>
                                        <MDBIcon icon='globe' className='mr-2'/>
                                        Global Settings
                                    </h3>
                                    </MDBRow>
                                    <MDBRow className="mt-5 mb-5">
                                    {Object.values(this.props.formData.global).map(setting => {
                                        return <div className='col-4' key={setting.name}>
                                            <label htmlFor={setting.name} className='text-capitalize'>
                                                {setting.friendly_name}
                                        </label>
                                        <MDBInput
                                            type='checkbox'
                                            id={setting.name}
                                            name={setting.name}
                                            containerClass='col-md-3 col-sm-12'
                                            onChange={this.handleCheckbox}
                                            checked={this.state.currentData[setting.name] || ''}
                                        />
                                        </div>
                                    })}
                                    </MDBRow>
                                    <hr/>
                                </MDBCol>
                                <MDBCol className='mt-5'>
                                    <MDBRow className='mt-5 col-3 mb-5'>
                                        <h3>
                                            <MDBIcon icon="money-bill-wave" className='mr-2' />
                                            Currencies
                                        </h3>
                                    </MDBRow>
                                    <MDBRow className="mt-5 mb-5">
                                        {Object.values(this.props.formData.currencies).map(setting => {
                                            return <div className='col-4 mb-5' key={setting.name}>
                                                <label htmlFor={setting.name} className='text-capitalize'>
                                                    {setting.friendly_name}
                                                </label>
                                                <MDBInput
                                                    type='checkbox'
                                                    id={setting.name}
                                                    name={setting.name}
                                                    containerClass='col-md-3 col-sm-12'
                                                    onChange={this.handleCheckbox}
                                                    checked={this.state.currentData[setting.name] || '' }
                                                />
                                            </div>
                                        })}
                                    </MDBRow>
                                    <div className='text-center mt-5 mb-5 '>
                                        <MDBBtn color='green' type='submit'>
                                            <MDBIcon icon='check' className='mr-2' />
                                            Save
                                        </MDBBtn>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBCard>
                </form>
            </section>
        )
    }
}

export default SettingsForm;
