import React from 'react';
import {MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBDataTableV5, MDBIcon, MDBRow, MDBView, MDBDatePicker} from "mdbreact";
import Amplify, { Auth } from 'aws-amplify';
import decode from 'jwt-decode';
import FilterByMagicAndSymbolForm from "../forms/FilterByMagicAndSymbolForm";
import qbotHelper from "../../helpers/qbotHelper";
import {CSVLink} from "react-csv";
import QbotHelper from "../../helpers/qbotHelper";
let loaded = false;

const ClosedStacksStats = () => {

    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: 'Symbol',
                field: 'symbol',
                width: 250,
            },
            {
                label: 'Magic',
                field: 'magic',
                width: 250,
            },
            {
                label: 'Buy / Sell',
                field: 'buy_sell',
                width: 250,
            },
            {
                label: 'Level Reached',
                field: 'level_reached',
                width: 250,
            },
            {
                label: 'Average Open Time',
                field: 'average_open_time',
                width: 250,
            },
            {
                label: 'Count',
                field: 'count',
                width: 250,
            },
        ],
        rows: [],
    });

    let tmpDataTable = JSON.parse(JSON.stringify(datatable))

    if (false === loaded) {
        let url = "https://botdashapi.fxsgnl.io/closed_stacks_stats?1=1";
        loadData(url);
        loaded = true;
    }

    function filter(magics, symbols) {
        let url = "https://botdashapi.fxsgnl.io/closed_stacks_stats?1=1";
        let newUrl = window.location.protocol + "//" + window.location.host+'/reports/closed-stacks-stats?';

        if (magics === 'ALL') {
            newUrl = newUrl.replace('&magic='+magics, '' );
            window.history.pushState({path:newUrl},'',newUrl);
        } else {
            window.history.pushState({path: newUrl}, '', newUrl + '&magic=' + magics);
            newUrl = newUrl + '&magic=' + magics;
            url += "&magic="+magics;
        }

        if (symbols === 'All') {
            newUrl = newUrl.replace('&symbol='+symbols, '' );
            window.history.pushState({path:newUrl},'',newUrl);
        } else {
            window.history.pushState({path: newUrl}, '', newUrl + '&symbol=' + symbols);
            url += "&symbols="+symbols;
        }


        loadData(url);
    }

    function loadData(url) {
        const tokens = Auth.currentSession().then(function (e) {
            let u = e.getIdToken().payload['cognito:username'];
            let jwtToken = e.getIdToken().jwtToken;
            let cognitoProfile = decode(jwtToken);

            fetch(url, {
                headers: {
                    "x-api-key": jwtToken
                },
            })
                .then((response) => response.json())
                .then((findresponse) => {
                    tmpDataTable.rows = findresponse;
                    for (let i = 0; i < tmpDataTable.rows.length; i++) {
                        tmpDataTable.rows[i].magic = qbotHelper.getQBotName(tmpDataTable.rows[i].magic);
                        tmpDataTable.rows[i].average_open_time = qbotHelper.minutesBreakdown(tmpDataTable.rows[i].average_open_time * 60);
                    }

                    setDatatable(tmpDataTable);
                    loaded = true;
                });
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    let magicId     = urlParams.get('magic');
    let symbol      = urlParams.get('symbol');

    let breakdownCsvData = [];
    let today = new Date().toLocaleString();
    today = today.replace(',', '_');
    let breakdownCsvName = 'closed_stacks_stats_'+today;


    for (let i = 0; i < tmpDataTable.rows.length; i++) {
        breakdownCsvData[i] = {
            'symbol': tmpDataTable.rows[i].symbol,
            'magic': tmpDataTable.rows[i].magic,
            'buy_sell': tmpDataTable.rows[i].buy_sell,
            'level reached': tmpDataTable.rows[i].level_reached,
            'average open time': tmpDataTable.rows[i].average_open_time,
            'count': tmpDataTable.rows[i].count
        }
    }

    if (loaded) {
        return (
            <div key={datatable.rows.length}>
            <MDBContainer fluid className="p-2">
                <MDBCard narrow className='pb-3'>
                    <MDBView
                        cascade
                        className='gradient-card-header green-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
                    >
                        <div className='text-left'>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='th-large' className='mt-0'/>
                            </MDBBtn>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon icon='columns' className='mt-0'/>
                            </MDBBtn>
                        </div>
                        <a href='#' className='white-text mx-3'>
                            Closed Stacks Stats
                        </a>
                        <div className='text-right'>
                            <MDBBtn outline color='white' rounded size='sm' className='px-2'>
                                <MDBIcon id="refresh" icon='info-circle' className='mt-0'/>
                            </MDBBtn>
                        </div>
                    </MDBView>
                    <MDBCardBody>
                        <CSVLink className='btn btn-primary' data={breakdownCsvData} filename={breakdownCsvName} >
                            <MDBIcon far icon="file-excel" className="pr-1" />
                            Export to CSV
                        </CSVLink>
                        <FilterByMagicAndSymbolForm
                            magics={QbotHelper.getQbotArray()}
                            symbols={QbotHelper.getSymbolArray()}
                            selected_symbol={symbol}
                            selected_magic={magicId}
                            onChangeFunction={filter}
                        />
                        <MDBDataTableV5
                            scrollX
                            striped
                            hover
                            entriesOptions={[5,15,25]}
                            entries={5}
                            pagesAmount={4}
                            data={datatable}
                            multipleCheckboxes
                            proCheckboxes
                            filledCheckboxes
                            proSelect
                            paging={false}
                            searchTop
                            searchBottom={false}
                            className='text-center'
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
            </div>
        );
    }
};

export default ClosedStacksStats;